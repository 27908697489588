<template>
  <div class="componentDetails">
    <p class="hdtitle">沟通详情</p>
    <div class="topBox">
      <div class="title"><p>{{detailsData.content==''?'【无名主题】':detailsData.content}}</p></div>
      <div class="rows" v-if="detailsData.businessStageName!==''"><p>商机阶段</p><span>{{detailsData.businessStageName}}</span></div>
      <div class="rows" v-if="detailsData.budgetTypeName!==''"><p>预算分类</p><span>{{detailsData.budgetTypeName}}</span></div>
      <div class="rows" v-if="detailsData.budget!==null"><p>预算金额</p><span>{{detailsData.budget}}</span></div>
      <div class="rows" v-if="detailsData.keyPointsForOutside!==''">
        <p>外部沟通要点</p>
        <van-text-ellipsis
          rows="2"
          :content="detailsData.keyPointsForOutside"
          expand-text="展开"
          collapse-text="收起"
        />
      </div>
      <div class="rows" v-if="detailsData.keyPointsForInside!==''">
        <p>内部沟通要点</p>
        <van-text-ellipsis
          rows="2"
          :content="detailsData.keyPointsForInside"
          expand-text="展开"
          collapse-text="收起"
        />
      </div>
    </div>
    <p class="hdtitle">需要支持</p>
    <div class="bottomBox">
      <div class="rows" v-if="detailsData.supportersName!==''"><p>支持人</p><span>{{detailsData.supportersName}}</span></div>
      <div class="rows" v-if="detailsData.supportTime!==''"><p>支持节点</p><span>{{detailsData.supportTime}}</span></div>
      <div class="rows" v-if="detailsData.supportContent!==''"><p>所需支持</p><span>{{detailsData.supportContent}}</span></div>
      <div class="rows" v-if="detailsData.note!==''">
        <p>备注说明</p>
        <van-text-ellipsis
          rows="2"
          :content="detailsData.note"
          expand-text="展开"
          collapse-text="收起"
        />
      </div>
      <div class="commentBox">
        <!-- 跟进回复 -->
        <div class="followMessageBox">
          <template v-for="(item,index) in commentsDatas" :key="index">
            <div class="tbox">
              <div class="t"><p class="user1">{{item.createrName}}</p><span class="time">{{item.createTime}}</span></div>
              <div class="commentChecks">
                <p v-for="user in getchecksUsers(item.id)" :key="user.id" :class="{check:user.state===2}" @click="checkUser(user.updateTime, user.state)">{{ user.checkUserName }}</p>
              </div>
              <div class="b">
                <div class="messageText" v-html="item.content"></div>
              </div>
              <div class="rows-btn">
                <!-- 删除 -->
                <a href="javascript:;" class="del-btn" v-show="item.creater === delBtnShow" @click="delMsg(item.id)"><van-icon name="clear" /></a>
                <!-- 确认 -->
                <a href="javascript:;" class="enter-btn" v-show="getEnterBtnShow(item.id)" @click="readMsg(item.id)"><van-icon name="checked" /></a>
                <!-- 回复 -->
                <a href="javascript:;" class="follow-btn" @click="followMsg(item.id)"><van-icon name="comment-circle" /></a>
              </div>
            </div>
            <commentsList ref="commentsTreeObj" :commentsDatas="item.follow" :commentsResDatas="commentsResDatas" :replyToId="item.replyTo" @followmsg="followMsg" @delmsg="delMsg"></commentsList>
            <div :class="{line:index !== commentsDatas.length -1 && item.follow == undefined,lineNone:item.follow !== undefined}"></div>
          </template>
        </div>
      </div>
    </div>
    <div class="leaveMessage">
      <div class="LMBox">
        <div class="LMText" v-html="msg" @click="showHtml"></div>
        <van-button round block type="primary" native-type="submit" class="leaveMessageBtn" @click="leaveMessage">跟进</van-button>
      </div>
    </div>
  </div>
  <!-- 再回复 -->
  <van-dialog v-model:show="show" show-cancel-button close-on-click-overlay @confirm="callBack">
    <div class="dialogMessageBox">
      <div class="c">
        <van-field
          v-model="msg1"
          clearable
          clear-trigger="always"
          name="msg1"
          rows="2"
          autosize
          type="textarea"
          autocomplete="off"
          maxlength="70"
          placeholder="请输入留言"
          show-word-limit
        />
      </div>
    </div>
  </van-dialog>
  <!-- 员工选择 -->
  <van-popup v-model:show="showPicker" position="bottom">
    <div class="toolbox-btn">
      <div class="cancel" @click="showPicker=false">取消</div>
      <div class="confirm" @click="showToasts()">确认</div>
    </div>
    <usersTree ref="treeData" :propsTreeDatas="propsTreeDatas" :allChecked="allChecked" :listIndex="listIndex" :key="forceRenderKey"></usersTree>
  </van-popup>
  <!-- 跟进消息填写 -->
  <van-popup v-model:show="showPickerEditorHtml" closeable position="bottom" @close="showToasts1('close')">
    <div class="followMsg">
      <div class="t">跟进消息</div>
      <div class="c">
        <van-form>
          <van-cell-group inset v-for="(item,index) in formList" :key="index">
            <div class="item">
              <van-field
                autocomplete="off"
                v-model="item.users"
                label="选择@人"
                placeholder="点击选择"
                class="fieldItem"
                rows="2"
                autosize
                type="textarea"
                @click="showPickerTree(index)"
                readonly
              />
              <van-field
                autocomplete="off"
                v-model="item.html"
                label="跟进消息"
                placeholder="填写内容"
                type="textarea"
                class="fieldItem"
                rows="5"
              />
              <!-- <div class="columndel" @click="columndel(index)">删除</div> -->
            </div>
          </van-cell-group>
          <!-- <div class="addbtn" @click="addformlist">+添加更多跟进</div> -->
          <div class="enter-btn" @click="enterform">确认</div>
        </van-form>
      </div>
    </div>
  </van-popup>
</template>
<script setup>
import { ref, onMounted, defineProps } from 'vue'
import { showToast, showConfirmDialog } from 'vant'
import {
  CustomerCommunicationReportsGet,
  CustomerCommunicationReportsAddComment,
  CustomerCommunicationReportsGetComments,
  CustomerCommunicationReportsGetCommentChecks,
  CustomerCommunicationReportsDelComment,
  CustomerCommunicationReportsCheckComment
} from '@/api/api'
const props = defineProps({
  reportIdObj: {
    type: Object,
    default: () => {}
  }
})
const show = ref(false)
const showPicker = ref(false)
const showPickerEditorHtml = ref(false)
const msg = ref('<p class="placeholder">填写消息</p>')
const msg1 = ref('')
const detailsData = ref({})

const treeData = ref({})
const propsTreeDatas = ref([])
const allChecked = ref([])
const forceRenderKey = ref(0)

const commentsDatas = ref([])
const commentsResDatas = ref([])
const delBtnShow = ref(JSON.parse(localStorage.getItem('userInfo')).user_id)
const callbackdata = ref({
  report: props.reportIdObj.report,
  replyTo: '',
  content: ''
})
const checksArr = ref([])

const listIdsIndex = ref('')
const listIndex = ref('')
// 跟进表单
const formList = ref([
  {
    users: '',
    usersArr: [],
    html: '',
    ids: []
  }
])

// 获取交流报告详情
onMounted(() => {
  getDetails()
  getComments()
})
const getDetails = () => {
  const params = {
    id: props.reportIdObj.report
  }
  CustomerCommunicationReportsGet(params).then((res) => {
    if (res.data.isSuccess) {
      detailsData.value = res.data.data
      if (res.data.data.supportTime !== null) {
        detailsData.value.supportTime = res.data.data.supportTime.slice(0, 10)
      } else {
        detailsData.value.supportTime = ''
      }
    }
  })
}
const showPickerTree = (index) => {
  listIndex.value = index
  forceRenderKey.value += 1
  showPicker.value = true
  listIdsIndex.value = index
  // 已选中的项 回传子组件
  if (formList.value[index].ids.length === 0) {
    propsTreeDatas.value = []
  } else {
    propsTreeDatas.value = formList.value[index].ids.map(item => `id_${item}`)
  }
}
// 弹出跟进表单弹框
const showHtml = () => {
  showPickerEditorHtml.value = true
}
// 表单新增栏
// const addformlist = () => {
//   formList.value.push({ users: '', html: '', ids: [], usersArr: [] })
// }
// 表单删除栏
// const columndel = (index) => {
//   formList.value = [...formList.value.slice(0, index), ...formList.value.slice(index + 1)]
// }
// 员工选择填入
const showToasts = () => {
  showPicker.value = false
  // 有内容才执行
  if (treeData.value.dataobj.name !== undefined) {
    formList.value[listIdsIndex.value].users = treeData.value.dataobj.name.toString()
    formList.value[listIdsIndex.value].usersArr = treeData.value.dataobj.name
    formList.value[listIdsIndex.value].ids = treeData.value.dataobj.id
    // 所有选中的内容回传
    allChecked.value = formList.value
  }
}
// 跟进消息填入
const showToasts1 = () => {
  showPickerEditorHtml.value = false
}
const enterform = () => {
  showPickerEditorHtml.value = false
  const html = formList.value.map(item => `${item.ids.map((id, index) =>
  `<span id="uid_${id}" class="highlight blue" contenteditable="false" data-cit-type="callUser">@${item.usersArr[index]}</span>`
  ).join('')}${item.html}`).join(' ')
  // 拼接html
  msg.value = `<p>${html}</p>`
}
// 提交跟进
const leaveMessage = () => {
  const data = {
    report: props.reportIdObj.report,
    content: msg.value,
    checkers: [...new Set(allChecked.value.flatMap(({ ids }) => [...ids]))].join(',')
  }
  CustomerCommunicationReportsAddComment(data).then((res) => {
    if (res.data.isSuccess) {
      showToast(res.data.message)
      getComments()
      msg.value = '<p class="placeholder">填写消息</p>'
    } else {
      showToast(res.data.message)
    }
  })
}
// 获取跟进内容
const getComments = () => {
  const params = {
    id: props.reportIdObj.report
  }
  CustomerCommunicationReportsGetComments(params).then((res) => {
    if (res.data.isSuccess) {
      commentsDatas.value = convertToTree(res.data.data).reverse()
      commentsResDatas.value = res.data.data.reverse()
    } else {
      showToast(res.data.message)
    }
  })
  CustomerCommunicationReportsGetCommentChecks(params).then((res) => {
    if (res.data.isSuccess) {
      checksArr.value = res.data.data
    } else {
      showToast(res.data.message)
    }
  })
}
// 根据id获取被@用户名
const getchecksUsers = (id) => {
  return checksArr.value.filter(item => item.comment === id)
}
const checkUser = (updateTime, state) => {
  if (state === 2) {
    showToast('已确认，' + updateTime)
  }
}
// 根据id判断是否显示确认按钮
const getEnterBtnShow = (id) => {
  const arr = checksArr.value.filter(item => item.comment === id)
  const name = JSON.parse(localStorage.getItem('userInfo')).user_name
  // console.log(arr.some(item => item.checkUserName === name && item.state === 1))
  return arr.some(item => item.checkUserName === name && item.state === 1)
}
// 扁平转树
const convertToTree = (flatData) => {
  flatData.forEach(i => {
    i.key = i.id
  })
  const treeData = []
  const map = new Map()
  let outputObj, replyTo
  for (let i = 0; i < flatData.length; i++) {
    replyTo = flatData[i].replyTo
    if (map.has(replyTo)) {
      if (!map.get(replyTo).follow) { map.get(replyTo).follow = [] }
      const obj = { ...flatData[i] }
      map.get(replyTo).follow.push(obj)
      map.set(flatData[i].id, obj)
    } else if (!map.has(replyTo) && replyTo === 0) {
      outputObj = { ...flatData[i] }
      treeData.push(outputObj)
      map.set(flatData[i].id, outputObj)
    }
  }
  return treeData
}
// 回复跟进消息
const followMsg = (id) => {
  show.value = true
  msg1.value = ''
  callbackdata.value.replyTo = id
}
const callBack = () => {
  callbackdata.value.content = msg1.value
  CustomerCommunicationReportsAddComment(callbackdata.value).then((res) => {
    if (res.data.isSuccess) {
      showToast(res.data.message)
      getComments()
    } else {
      showToast(res.data.message)
    }
  })
}
// 删除跟进消息
const delMsg = (id) => {
  showConfirmDialog({
    message:
    '确认删除该条消息吗'
  })
    .then(() => {
    // on confirm
      CustomerCommunicationReportsDelComment({ id }).then((res) => {
        if (res.data.isSuccess) {
          showToast(res.data.message)
          getComments()
        } else {
          showToast(res.data.message)
        }
      })
    })
    .catch(() => {
    // on cancel
    })
}
const readMsg = (id) => {
  showConfirmDialog({
    message:
    '确认已查看消息'
  })
    .then(() => {
      // on confirm
      CustomerCommunicationReportsCheckComment({ id }).then((res) => {
        if (res.data.isSuccess) {
          showToast(res.data.message)
          getComments()
        } else {
          showToast(res.data.message)
        }
      })
    })
    .catch(() => {
      // on cancel
    })
}
</script>

<style lang="scss">
  .componentDetails{
    width: 100%;
    height: auto;
    background:#fff;
    .hdtitle{
      font-size: 0.15rem;
      font-weight: 600;
      color: #009688;
      line-height: 0.34rem;
      padding-left: 0.05rem;
    }
    .topBox{
      width: 100%;
      height: auto;
      padding: 0.1rem 0.1rem;
      box-sizing: border-box;
    }
    .bottomBox{
      width: 100%;
      height: auto;
      background: #fff;
      padding: 0.1rem 0.1rem;
      border-bottom: 0;
      box-sizing: border-box;
      position: relative;
      .rows{
        width: 100%;
        height: auto;
        font-size: 0.13rem;
        line-height: 0.24rem;
        text-align: justify;
        color:#333;
        margin-bottom: 0.1rem;
        display: flex;
        p{
          font-size: 0.13rem;
          line-height: 0.24rem;
          text-align: justify;
          color:#333;
          width: 0.86rem;
          text-align-last:justify;
          flex-shrink:0;
          padding-right: 0.12rem;
          position: relative;
          &::after{
            content: '：';
            position: absolute;
            right: -0.04rem;
            top: 0;
          }
          &.clamp2{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
          }
        }
      }
      .commentBox{
        width: 100%;
        height: auto;
        padding: 0;
        box-sizing: border-box;
        .followMessageBox{
          width: 100%;
          height: 100%;
          padding: 0 !important;
          box-sizing: border-box;
          overflow: auto;
          .line{
            width: 100%;
            height: 0.01rem;
            background: #e6e6e6;
            margin:0.07rem 0;
            box-shadow: 0 0.02rem 0.07rem 0 rgba(0,0,0,0.05);
          }
          .lineNone{
            width: 100%;
            height: 0.01rem;
            margin:0.07rem 0;
            box-shadow: 0 0.02rem 0.07rem 0 rgba(0,0,0,0.05);
          }
          .tbox{
            margin-top: 0.1rem;
            position: relative;
            .t{
              width: 100%;
              height: auto;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              line-height: 0.26rem;
              .user1{
                font-size: 0.16rem;
                color: #ffbf6b;
                font-weight: bold;
              }
              .time{
                font-size: 0.15rem;
                color: #666;
                font-weight: bold;
                margin-left: 0.1rem;
                // margin-top: 0.02rem;
              }
            }
            .commentChecks{
              width: 100%;
              height: auto;
              display: flex;
              justify-content: flex-start;
              flex-wrap:wrap;
              margin-bottom: 0.05rem;
              p{
                font-size: 0.12rem;
                padding: 0.02rem 0.06rem 0.015rem 0.06rem;
                margin: 0.05rem 0.04rem;
                color:#fff;
                background: #96999b;
                border-radius: 0.03rem;
                &.check{
                  background: #009688;
                }
              }
            }
            .b{
              width: 100%;
              height: auto;
              line-height: 0.24rem;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              .messageText{
                font-size: 0.13rem;
                margin-left: 0.05rem;
                p{
                  color: #333;
                  font-weight: bold;
                  .highlight{
                    font-size: 0.13rem;
                    color:#4095e5;
                    margin-right: 0.04rem;
                  }
                }
              }
            }
            .rows-btn{
              width:50%;
              height: 0.28rem;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              position: absolute;
              right: 0;
              top: 0;
              .follow-btn{
                font-size: 0.18rem;
                color: #009688;
                line-height: 0.28rem;
                margin-left:0.07rem;
              }
              .enter-btn{
                font-size: 0.18rem;
                color: #ffb800;
                line-height: 0.28rem;
                margin-left:0.07rem;
              }
              .del-btn{
                font-size: 0.18rem;
                color: #ff5722;
                line-height: 0.28rem;
              }
            }
          }
        }
      }
    }
    .leaveMessage{
      width: 100%;
      height: auto;
      margin-bottom: 0.2rem;
      .p{
        font-size: 0.13rem;
        line-height: 0.2rem;
        color: #0b57d0;
        padding-left: 0.15rem;
        box-sizing: border-box;
      }
      .LMBox{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .LMText{
          width: 100%;
          min-height: 0.68rem;
          border: 0.01rem solid #e6e6e6;
          padding:0.05rem 0.1rem 0.05rem 0.15rem;
          box-sizing: border-box;
          margin-bottom: 0.1rem;
          .placeholder{
            font-size: 0.14rem;
            color:#999;
          }
          p{
            font-size: 0.14rem;
            color:#333;
            .highlight{
              &.blue{
                color: #47b0ff;
                font-weight: bold;
              }
            }
          }
        }
        .leaveMessageBtn{
          width: 1.68rem;
          height: 0.35rem;
          font-size: 0.15rem;
          line-height: 0.3rem;
          text-align: center;
          background: #009688;
          color: #fff;
          border: none;
          border-radius: 0.04rem;
        }
      }
    }
  }
  .dialogMessageBox{
    width: 100%;
    height: auto;
    .t{
      background:#999;
      color:#333;
      padding:0.1rem 0.2rem;
      box-sizing:border-box;
      font-size:0.13rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .c{
      width: 100%;
      .van-cell{
        width: 100%;
        border: 0.01rem solid #ebebeb;
      }
    }
    .b{}
  }
  .treeBox{
    width: 100%;
    height: auto;
  }
  .followMsg{
    width: 100%;
    height: 80vh;
    .t{
      font-size: 0.15rem;
      line-height: 0.5rem;
      color: #333;
      font-weight: bold;
      text-align: left;
      padding-left: 0.24rem;
      box-sizing: border-box;
      position: relative;
      background:#e6e6e6;
      &::after{
        content: "";
        width: 0.03rem;
        height: 0.16rem;
        background: #009688;
        position: absolute;
        left: 0.15rem;
        top: 0.15rem;
      }
    }
    .c{
      .item{
        // border:0.01rem solid #009688;
        margin-top: 0.1rem;
        border-radius: 0.08rem;
        .fieldItem{
          border-radius: 0.08rem;
          .van-field__body{
            height: 100%;
          }
        }
        .columndel{
          color:#ffa201;
          font-size: 0.12rem;
          float:right;
          width: 0.5rem;
          height: 0.25rem;
          line-height: 0.25rem;
          text-align: right;
        }
      }
      .addbtn{
        font-size: 0.14rem;
        color:#009688;
        text-align: center;
        line-height: 0.4rem;
      }
      .enter-btn{
        width: 1.68rem;
        height: 0.35rem;
        font-size: 0.15rem;
        line-height: 0.3rem;
        text-align: center;
        background: #009688;
        color: #fff;
        border-radius: 0.04rem;
        margin: 0.2rem auto;
      }
    }
    .van-cell-group{
      margin:0;
    }
    .van-cell:after{
      // border-bottom:0.01rem solid #b1b1b1
      border:0
    }
  }
  .van-text-ellipsis__action{
    line-height: 0.24rem;
  }
</style>
