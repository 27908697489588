<template>
  <div class="home-title">
    <div class="home-back">
      <router-link to="/BusinessCommunicationThemes">
        <van-icon name="wap-home" size="0.25rem" color="#fff"/>
      </router-link>
    </div>
    <div class="msg-notification">
      <router-link to="/msgList">
        <van-icon name="bell" size="0.25rem" color="#fff" :content="content" max="99" position="top-left"/>
      </router-link>
    </div>
  </div>
  <router-view/>
</template>
<script setup>
import { onMounted, onUnmounted, h, ref } from 'vue'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import {
  innerMessagesGetUnreadCount,
  InnerMessagesGetMine
} from '@/api/api'
const router = useRouter()
const content = ref()
// 创建VNode
const type2 = h('div', {
  class: 'messageInfo',
  onClick: () => console.log('onClick')
}, [
  h('p', '消息通知'),
  h('a', {
    href: `${process.env.VUE_APP_BASE_URL}/#/msgList`
  }, '客户沟通报告的“跟进”中，有人提到你，请及时查看')
])

// 每30秒发送一次请求
const intervalTime = 40000
// 定时器
let intervalId

// 获取当前是否有未读消息，如果有则显示消息通知
const getNewMsg = () => {
  innerMessagesGetUnreadCount().then(res => {
    if (res.data.data > 0) {
      content.value = res.data.data
      const data = {
        condition: {
          keyword: ''
        },
        pager: {
          index: 1,
          size: 1,
          sortField: 'updateTime',
          sortType: 'DESC',
          sort: 'updateTime DESC'
        }
      }
      InnerMessagesGetMine(data).then((res) => {
        // console.log(res.data.data[0])
        const obj = res.data.data[0]
        const sendTime = new Date(obj.sendTime)
        const now = new Date()
        const timeDiff = now.getTime() - sendTime.getTime()
        if (timeDiff < 39000) {
          if (obj.type === 2) {
            message.info({
              content: type2,
              duration: 3
            })
          }
        }
      })
    }
  })
}
// 监听路由变化
router.beforeEach((to, from, next) => {
  // console.log('路由变化了，从', from.path, '到', to.path)
  next()
  innerMessagesGetUnreadCount().then(res => {
    if (res.data.data > 0) {
      content.value = res.data.data
    }
  })
  if (to.path !== '/msgList') {
    // getNewMsg()
    // intervalId = setInterval(() => {
    //   getNewMsg()
    // }, intervalTime)
  } else {
    // clearInterval(intervalId)
  }
})
// 加载时添加定时器
onMounted(() => {
  intervalId = setInterval(() => {
    getNewMsg()
  }, intervalTime)
})
// 卸载时删除定时器
onUnmounted(() => {
  clearInterval(intervalId)
})
</script>
<style lang="scss">
  p{
    margin-bottom: 0 !important;
  }
  .ant-message-custom-content{
    display: flex;
  }
  .messageInfo {
    p,a{
      font-size: 0.14rem;
      line-height: 0.2rem;
      text-align: left;
    }
    a{
      display: inline-block;
    }
  }
  .home-title{
    width: 100%;
    height: 0.4rem;
    background: rgba(0, 150, 136, 0.7);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.1rem;

    .home-back, .msg-notification{
      width: 0.3rem;
      height: 0.3rem;
      a{
        display: block;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .van-badge--top-left{
          top: 0.05rem;
        }
      }
    }
  }
</style>
