<template>
  <div class="sbody">
    <div class="searchbox">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <div class="inputbox">
            <van-field
              v-model="keyword"
              name="keyword"
              clearable
              clear-trigger="always"
              autocomplete="off"
              placeholder="关键字"
              @clear="search()"
            />
          </div>
          <div class="inputbox">
            <van-field
              v-model="phone"
              name="phone"
              clearable
              clear-trigger="always"
              autocomplete="off"
              placeholder="电话"
              @clear="search()"
            />
          </div>
          <div class="inputbox">
            <van-field
              v-model="companys"
              name="companys"
              readonly
              :right-icon="companys ? 'clear' : null"
              @click-right-icon.stop="clearContent('companys')"
              autocomplete="off"
              placeholder="客户公司"
              @click="showCompanys"
            />
          </div>
          <div class="inputbox">
            <van-field
              v-model="sales"
              name="sales"
              readonly
              :right-icon="sales ? 'clear' : null"
              @click-right-icon.stop="clearContent('sales')"
              autocomplete="off"
              placeholder="责任商务"
              @click="showSales"
            />
          </div>
        </van-cell-group>
        <van-button round block type="primary" native-type="submit" class="submitbtn" @click="search">搜索</van-button>
      </van-form>
    </div>
    <div class="cclist">
      <div class="listarr">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <template v-for="(item,index) in cclistData" :key="index">
            <div v-if="listCheckedArr.includes(item.id)" class="list checked" @click="rechecked(item.id,item.name)">
              <span class="silk-ribbon"><span></span></span>
              <div class="title"><p>{{item.name}}</p></div>
              <div class="listitem" v-if="item.companysName!==''"><p><span>公司：</span>{{item.companysName}}</p></div>
              <div class="listitem" v-if="item.currentPhones!==''"><p><span>手机：</span>{{item.currentPhones}}</p></div>
              <div class="listitem" v-if="item.departments!==''"><p><span>职位：</span>{{item.departments}}</p></div>
              <div class="listitem" v-if="item.currentSalesName!==''"><p><span>责任商务：</span>{{item.currentSalesName}}</p></div>
              <div class="listitem" v-if="item.fieldsName!==''"><p><span>领域：</span>{{item.fieldsName}}</p></div>
              <div class="listitem" v-if="item.productsName!==''"><p><span>产品：</span>{{item.productsName}}</p></div>
            </div>
            <div v-else class="list" @click="checkedCC(item.id,item.name)">
              <div class="title"><p>{{item.name}}</p></div>
              <div class="listitem" v-if="item.companysName!==''"><p><span>公司：</span>{{item.companysName}}</p></div>
              <div class="listitem" v-if="item.currentPhones!==''"><p><span>手机：</span>{{item.currentPhones}}</p></div>
              <div class="listitem" v-if="item.departments!==''"><p><span>职位：</span>{{item.departments}}</p></div>
              <div class="listitem" v-if="item.currentSalesName!==''"><p><span>责任商务：</span>{{item.currentSalesName}}</p></div>
              <div class="listitem" v-if="item.fieldsName!==''"><p><span>领域：</span>{{item.fieldsName}}</p></div>
              <div class="listitem" v-if="item.productsName!==''"><p><span>产品：</span>{{item.productsName}}</p></div>
            </div>
          </template>
        </van-list>
      </div>
    </div>
  </div>
  <!-- 责任编辑 -->
  <van-popup v-model:show="showPicker" position="bottom">
    <van-picker
      :columns="typeArr"
      :value="typeArr[0]"
      @confirm="showPickerType"
      @cancel="showPicker = false"
    />
  </van-popup>
  <!-- 公司简称 -->
  <van-popup v-model:show="showPicker1" position="bottom" @close="showPicker1=false">
    <div class="toolbox-btn">
      <div class="cancel" @click="showPicker1=false">取消</div>
      <div class="confirm" @click="showToast1()">确认</div>
    </div>
    <checkedSelect :datatype="datatype" :checkedCompanys="checkedCompanys" ref="csData" :key="checkedSelectIndex"></checkedSelect>
  </van-popup>
</template>

<script setup>
import { ref, defineExpose, defineProps, onMounted } from 'vue'
import { CustomerContactsGetList, CustomerContactsGetDetail } from '@/api/api'
import { showToast } from 'vant'

const props = defineProps({
  listCheckedId: {
    type: Array,
    default: () => []
  }
})
const keyword = ref('')
const phone = ref('')
const companys = ref('')
const companysId = ref('')
const sales = ref('')
const salesId = ref('')
const pageIndex = ref(0)
const pageSize = ref(10)
const loading = ref(false)
const finished = ref(true)

const listCheckedArr = ref([]) // 选中id
const listCheckedArrString = ref([]) // 选中id字符串
const listCheckedStringArr = ref([]) // 选中name
const listCheckedString = ref([]) // 选中name字符串

const cclistData = ref([]) // 客户联系人列表

const showPicker = ref(false)
const showPicker1 = ref(false)
const checkedSelectIndex = ref(0)
const checkedCompanys = ref([])
const csData = ref(null) // 公司简称组件传值
const typeArr = ref([])
const datatype = ref('')

// 客户联系人列表
onMounted(() => {
  listdata()
})
// 触底
const onLoad = () => {
  // console.log('触底')
  loading.value = true
  pageIndex.value++
  listdata()
}
// 搜索
const search = () => {
  // console.log('搜索')
  pageIndex.value = 1
  cclistData.value = []
  listdata()
}
const listdata = () => {
  const data = {
    condition: {
      keyword: keyword.value,
      phone: phone.value,
      companys: companysId.value,
      sales: salesId.value,
      currentOnly: true
    },
    pager: {
      index: pageIndex.value,
      size: pageSize.value
    }
  }
  CustomerContactsGetList(data).then((res) => {
    // console.log(res.data.data)
    if (res.data.isSuccess) {
      const totalPage = Math.ceil(res.data.dataCount / pageSize.value)
      const listArr = cclistData.value
      listArr.push(...res.data.data)
      cclistData.value = listArr
      loading.value = false
      if (totalPage < pageIndex.value || totalPage === pageIndex.value) {
        finished.value = true
      } else {
        finished.value = false
      }
      if (props.listCheckedId.length > 0) {
        listCheckedArr.value = props.listCheckedId
        listCheckedArr.value.forEach((id) => {
          showDetail(id)
        })
        listCheckedStringArr.value = cclistData.value.filter(obj => props.listCheckedId.includes(obj.id)).map(i => i.name)
        listCheckedString.value = listCheckedStringArr.value.join(',')
      }
    } else {
      showToast(res.data.message)
    }
  })
}

// 查看客户联系人详情
const showDetail = (id) => {
  CustomerContactsGetDetail({ id }).then((res) => {
    if (res.data.isSuccess) {
      listCheckedArrString.value.push({ customerContact: res.data.data.id, careerExperience: res.data.data.careerExperiences[0].id })
      listCheckedArrString.value = [...new Set(listCheckedArrString.value.map(JSON.stringify))].map(JSON.parse)
    } else {
      showToast(res.data.message)
    }
  })
}
// 选中
const checkedCC = (id, name) => {
  listCheckedArr.value.push(id)
  listCheckedStringArr.value.push(name)
  showDetail(id)
  // 数组去重
  listCheckedArr.value = [...new Set(listCheckedArr.value)]
  listCheckedStringArr.value = [...new Set(listCheckedStringArr.value)]
  const uniqueArr = [...new Set(listCheckedArrString.value.map(JSON.stringify))].map(JSON.parse)
  listCheckedArrString.value = [...new Set(uniqueArr)]
  // console.log(listCheckedArrString.value)
  listCheckedString.value = listCheckedStringArr.value.join(',')
}
// 取消选中
const rechecked = (id, name) => {
  const ind1 = listCheckedArr.value.indexOf(id)
  const ind2 = listCheckedStringArr.value.indexOf(name)
  // 删除数组中对应元素
  listCheckedArr.value.splice(ind1, 1)
  listCheckedStringArr.value.splice(ind2, 1)
  listCheckedArrString.value = filterByArray(listCheckedArrString.value, listCheckedArr.value)
  // console.log(listCheckedArrString.value)
  listCheckedString.value = listCheckedStringArr.value.join(',')
}
// 根据选中id筛选客户联系人数组，重新生成新数组
const filterByArray = (arr1, arr2) => {
  return arr1.filter(item => arr2.includes(item.customerContact))
}
// 公司简称
const showCompanys = () => {
  showPicker1.value = true
  datatype.value = 1
  console.log(companysId.value)
  checkedCompanys.value = companysId.value.split(',').map((str) => {
    return parseInt(str)
  })
  checkedSelectIndex.value = checkedSelectIndex.value + 1
}
const showToast1 = () => {
  showPicker1.value = false
  companys.value = csData.value.checkedCompanysString
  companysId.value = csData.value.checkedCompanysListValue
}
// 责任编辑
const salesTypeArr = [
  { text: '黄磊', value: 135 },
  { text: '雷蕾', value: 188 },
  { text: '罗敏', value: 187 },
  { text: '邵轶', value: 71 },
  { text: '宋绍甫', value: 134 },
  { text: '王鸿', value: 55 },
  { text: '吴婧', value: 145 },
  { text: '姚华', value: 105 },
  { text: '赵平', value: 144 },
  { text: '其他', value: 131 }
]
const showSales = () => {
  showPicker.value = true
  typeArr.value = salesTypeArr
}
const showPickerType = ({ selectedOptions }) => {
  sales.value = selectedOptions[0]?.text
  salesId.value = selectedOptions[0]?.value
  showPicker.value = false
}
// 清空填写内容
const clearContent = (refName) => {
  if (refName === 'companys') {
    companys.value = ''
    companysId.value = ''
  } else if (refName === 'sales') {
    sales.value = ''
    salesId.value = ''
  }
}
// 对外暴露
defineExpose({
  listCheckedArrString,
  listCheckedString
})
</script>
<style lang="scss">
.sbody{
  width: 100%;
  height: 80vh;
  background:#ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .van-cell-group--inset{
    margin:0;
    background:none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .van-cell{
    padding:0;
  }
  .van-form{
    width: 100%;
  }
  .searchbox{
    width: 100%;
    height: auto;
    background: #fff;
    padding: 0.5rem 0.2rem 0.2rem 0.2rem;
    box-sizing: border-box;
    .inputbox{
      width: 48%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.1rem;
      .van-cell{
        padding:0 !important;
        &:after{
          border:0;
        }
        .van-field__body{
          height: 0.32rem;
          width: 100%;
          border:0.01rem solid #e6e6e6;
          box-sizing: border-box;
          border-radius: 0.05rem;
          padding: 0 0.1rem;
          input{
            font-size: 0.14rem;
          }
        }
      }
    }
    .submitbtn{
      width: 1.2rem;
      height: 0.35rem;
      background-color: #009688;
      border: none;
      color: #fff;
      font-size: 0.14rem;
      border-radius: 0.08rem;
      margin:0.08rem auto 0.04rem auto;
    }
  }
  .cclist{
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem;
    box-sizing: border-box;
    .listarr{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .van-list{
        width: 100%;
      }
      .list{
        width: 100%;
        height: auto;
        background:#fff;
        border-radius: 0.1rem;
        border:0.02rem solid #e6e6e6;
        padding: 0.12rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom:0.1rem;
        position: relative;
        &.checked{
          border:0.02rem solid #009688;
          background: #f8fffd;
        }
        .silk-ribbon{
          position:absolute;
          top:-6px;
          right:10px;
          display: flex;
          &:after {
            position:absolute;
            content:"";
            display:block;
            width:0;
            height:0;
            top: 0.22rem;
            border-left:53px solid transparent;
            border-right:53px solid transparent;
            border-top:10px solid #10a597;
          }
          span {
            position:relative;
            display:inline-block;
            text-align:center;
            background:#01a595;
            font-size:14px;
            line-height:1;
            padding:12px 8px 10px;
            border-top-right-radius:8px;
            width:90px;
            &::before{
              position:absolute;
              content:"";
              display:block;
              background:#008f81;
              height:6px;
              width:6px;
              left:-6px;
              top:0;
            }
            &::after{
              position:absolute;
              content:"";
              display:block;
              background:#0e8a7f;
              height:6px;
              width:8px;
              border-radius:8px 8px 0 0;
              left:-8px;
              top:0;
            }
          }
        }
        .title{
          width: 100%;
          height: auto;
          margin-bottom: 0.08rem;
          p{
            font-size: 0.17rem;
            font-weight: bold;
            line-height: 0.24rem;
            text-align: justify;
            color:#333;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
          }
        }
        .listitem{
          width: 100%;
          height: auto;
          span{
            display: inline-block;
            width: 0.7rem;
            text-align: justify;
            text-align-last: justify;
          }
          p{
            font-size: 0.13rem;
            line-height: 0.2rem;
            text-align: justify;
            color:#333;
          }
        }
      }
    }
  }
}
</style>
