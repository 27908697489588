<template>
  <template v-for="item in commentsDatas" :key="item.id">
    <div class="bbox" @click="followMsg(item.id)">
      <div class="box">
        <!-- 删除 -->
        <a href="javascript:;" class="delBtn" v-show="item.creater === delBtnShow" @click.stop="delMsg(item.id)"><van-icon name="clear" /></a>
        <span class="time">{{item.createTime}}</span>
        <div class="center">
          <p class="followtext"><span class="user3">{{item.createrName}}：</span>回复<span class="user2">@{{getName(item.replyTo)}}</span>{{item.content}}</p>
        </div>
      </div>
    </div>
    <commentsList ref="commentsTreeObj" :commentsDatas="item.follow" :commentsResDatas="commentsResDatas" :replyToId="item.replyTo" @followmsg="followMsg" @delmsg="delMsg"></commentsList>
  </template>
</template>

<script setup>
import { ref, defineExpose, onMounted, defineProps, defineEmits, watch } from 'vue'
const props = defineProps({
  commentsDatas: {
    type: Array,
    default: () => []
  },
  commentsResDatas: {
    type: Array,
    default: () => []
  },
  replyToObj: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['delmsg', 'followmsg'])
const listData = ref([])
const dataobj = ref({})
const delBtnShow = ref(JSON.parse(localStorage.getItem('userInfo')).user_id)

// 初始渲染后
onMounted(() => {
  watch(() => props.commentsDatas, (newVal) => {
    listData.value = newVal
  })
})
// 根据replyTo获得name
const getName = (id) => {
  if (props.commentsResDatas !== undefined) {
    return props.commentsResDatas.find(item => item.id === id).createrName
  }
}
// 删除回复
const delMsg = (id) => {
  emit('delmsg', id)
}
// 回复
const followMsg = (id) => {
  emit('followmsg', id)
}
// 对外暴露
defineExpose({
  dataobj
})

</script>
<style lang="scss" scoped>

.bbox{
  width: 100%;
  height: auto;
  background: #efefef;
  padding: 0.08rem 0 0.08rem 0.08rem;
  box-sizing: border-box;
  .box{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    .delBtn{
      font-size: 0.18rem;
      color: #ff5722;
      position: absolute;
      right: 0;
      top: 0;
      line-height: 0.28rem;
    }
    .time{
      font-size: 0.12rem;
      line-height: 0.2rem;
      color: #666;
      font-weight: bold;
      margin-left: 0.05rem;
      margin-top: 0.02rem;
    }
    .center{
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .followtext{
        font-size: 0.12rem;
        line-height: 0.23rem;
        color: #333;
        font-weight: bold;
        margin-left: 0.05rem;
        .user3{
          font-size: 0.12rem;
          color: #cba43f;
          font-weight: bold;
        }
        .user2{
          font-size: 0.12rem;
          color:#4095e5;
          margin: 0 0.04rem;
        }
      }
    }
  }
}
</style>
