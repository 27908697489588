<template>
  <div class="homeDetails" id="pageTop">
    <div class="topBox">
      <div class="title">
        <van-text-ellipsis
          rows="2"
          :content="'主题：'+ detailsData.content"
          expand-text="展开"
          collapse-text="收起"
        />
      </div>
      <div class="rows" v-if="detailsData.businessStageName!==''"><p>商机阶段</p><span>{{detailsData.businessStageName}}</span></div>
      <div class="rows"><p>创建人</p><span>{{detailsData.createrName}}</span></div>
      <div class="rows">
        <p>客户公司</p>
        <van-text-ellipsis
          rows="2"
          :content="detailsData.customerCompanyNames"
          expand-text="展开"
          collapse-text="收起"
        />
      </div>
      <div class="rows" v-if="detailsData.note!==''">
        <p>项目/需求简述</p>
        <van-text-ellipsis
          rows="2"
          :content="detailsData.note"
          expand-text="展开"
          collapse-text="收起"
        />
      </div>
    </div>
    <div class="containers">
      <p class="titleP">沟通报告<van-tag type="primary">{{detailsData.reportNum}}</van-tag></p>
      <!-- <div class="adda"><button class="addbtn" @click="addbct">+</button></div> -->
      <van-icon name="add" class="icon-adda" @click="addbct"/>
      <div class="collapse-containers">
        <van-collapse v-model="collapseActive" ref="collapse">
          <template v-for="(item,index) in listData" :key="index">
            <van-collapse-item :name="index" :id="'collapse-item'+item.id">
              <template #title>
                <div class="collapse-title">{{item.name}}{{item.note!==''?' - '+item.note :' - '+'【报告未命名】'}}</div>
              </template>
              <div class="report-details" @click.stop="">
                <div class="edit-popup-right-icon" @click="editPopupShow(item.id)">
                  <van-icon name="ellipsis" size="0.2rem"/>
                </div>
                <div class="components-iframe">
                  <report :reportIdObj="{report: item.id}"></report>
                </div>
              </div>
            </van-collapse-item>
          </template>
        </van-collapse>
      </div>
    </div>
  </div>
  <van-popup v-model:show="show" position="bottom">
    <div class="edit-btn-box">
      <p class="btn" @click="pageEdit">修改</p>
      <p class="btn" @click="pageDel">删除</p>
      <p class="btn" @click="show = false">取消</p>
    </div>
  </van-popup>
  <!-- 浮动气泡 -->
  <van-floating-bubble
   gap="12"
   @click="floatingBubble"
   @offset-change="onOffsetChange"
  >
   <div class="custom-content">
    <van-icon name="list-switch"/>
    <van-popover placement="left-end" :actions="actions" v-show="showPopover" @select="onSelect"></van-popover>
   </div>
  </van-floating-bubble>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { showToast } from 'vant'
import {
  BusinessCommunicationThemesGetDetail,
  // CustomerCommunicationReportsGet,
  CustomerCommunicationReportsDel
} from '@/api/api'
import { formatDate } from '@/api/utils'
const router = useRouter()
const route = useRoute()
// 页面信息
const detailsData = ref({})
// 沟通列表
const listData = ref([])
// 手风琴组件展开记录
const collapseActive = ref([0])
const collapse = ref(null)
// 详情id
const detailsId = ref('')
// 操作按钮显示
const show = ref(false)
// 悬浮按钮显示
const showPopover = ref(true)
// 悬浮内容
let actions = []

// 获取主题详情
onMounted(() => {
  getDetails()
})
const getDetails = () => {
  const params = {
    id: route.query.id
  }
  BusinessCommunicationThemesGetDetail(params).then((res) => {
    if (res.data.isSuccess) {
      detailsData.value = res.data.data
      const arrList = res.data.data.reports.map(item => {
        return {
          ...item,
          name: formatDate(item.name)
        }
      })
      // 沟通列表
      listData.value = arrList.reverse()
      // 悬浮气泡列表
      const actionsArr = arrList.map(item => {
        return {
          text: item.name,
          id: item.id
        }
      })
      actionsArr.push(
        {
          text: '返回顶部',
          id: '-1'
        }, {
          text: '全部展开',
          id: '-2'
        }, {
          text: '全部折叠',
          id: '-3'
        }
      )
      actions = actionsArr
      if (route.query.report) {
        collapseActive.value = [listData.value.findIndex(item => item.id === route.query.report * 1)]
      }
    } else {
      showToast(res.data.message)
    }
  })
}
// 修改详情
const editPopupShow = (id) => {
  detailsId.value = id
  show.value = true
}
const pageEdit = () => {
  const id = detailsId.value
  router.push({
    path: '/addBCTReport',
    query: { id, theme: route.query.id, type: 2 }
  })
}
// 删除详情
const pageDel = () => {
  const id = detailsId.value
  const params = {
    id
  }
  CustomerCommunicationReportsDel(params).then((res) => {
    if (res.data.isSuccess) {
      getDetails()
      showToast(res.data.message)
      show.value = false
    } else {
      showToast(res.data.message)
    }
  })
}
// 新增沟通报告
const addbct = () => {
  router.push({
    path: '/addBCTReport',
    query: { theme: route.query.id, type: 3 }
  })
}
// 显示悬浮内容
const floatingBubble = () => {
  showPopover.value = true
}
const onOffsetChange = (offset) => {
  showPopover.value = false
}
// 选择悬浮内容回调
const onSelect = (action) => {
  if (action.id === '-1') {
    document.getElementById('pageTop').scrollIntoView({ behavior: 'smooth' }) // 返回顶部
  } else if (action.id === '-2') {
    collapse.value.toggleAll(true) // 展开所有
  } else if (action.id === '-3') {
    collapse.value.toggleAll(false) // 折叠所有
  } else {
    const anchor = `collapse-item${action.id}`
    const el = document.getElementById(anchor)
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>
<style lang="scss">
  .homeDetails{
    width: 100%;
    height: 100vh;
    background:#fff;
    .topBox{
      width: 100%;
      height: auto;
      padding: 0.1rem 0.15rem;
      box-sizing: border-box;
      .title{
        width: 100%;
        height: auto;
        margin-bottom: 0.08rem;
        font-size: 0.17rem;
        font-weight: bold;
        line-height: 0.24rem;
        text-align: justify;
        color:#333;
      }
      .rows{
        width: 100%;
        height: auto;
        font-size: 0.13rem;
        line-height: 0.24rem;
        text-align: justify;
        color:#333;
        margin-top: 0.1rem;
        display: flex;
        p{
          font-size: 0.13rem;
          line-height: 0.24rem;
          text-align: justify;
          color:#333;
          width: 0.86rem;
          text-align-last:justify;
          flex-shrink:0;
          padding-right: 0.12rem;
          position: relative;
          &::after{
            content: '：';
            position: absolute;
            right: -0.04rem;
            top: 0;
          }
        }
        .van-text-ellipsis{
          width: 100%;
          line-height: 0.24rem;
        }
      }
    }
    .containers{
      width: 100%;
      height: auto;
      border-top: 0.01rem solid #e6e6e6;
      padding: 0.1rem 0.15rem;
      box-sizing: border-box;
      position: relative;
      .titleP{
        font-size: 0.16rem;
        font-weight: bold;
        padding:0.1rem 0;
        .van-tag{
          margin-left: 0.1rem;
        }
      }
      .icon-adda{
        font-size: 0.3rem;
        color: #009688;
        position: absolute;
        right: 0.15rem;
        top: 0.15rem;
      }
      .adda{
        width: 0.3rem;
        height: 0.3rem;
        position: absolute;
        right: 0.15rem;
        top: 0.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .addbtn{
          width: 100%;
          height: 100%;
          background: #009688;
          color: #fff;
          border: none;
          border-radius: 0.04rem;
          font-size: 0.3rem;
          line-height: 0.32rem;
        }
      }
      .collapse-containers{
        width: 100%;
        height: auto;
        .collapse-title{
          color:var(--van-tag-primary-color);
        }
        .title,.collapse-title{
          font-size: 0.16rem;
          font-weight: bold;
          line-height: 0.4rem;
        }
        .report-details{
          width: 100%;
          height: auto;
          position: relative;
          .edit-popup-right-icon{
            position: absolute;
            width:0.2rem;
            height: 0.2rem;
            right: 0.04rem;
            top: 0;
          }
          .components-iframe{
            width:100%;
            height: auto;
            margin-top: 0.1rem;
          }
        }
      }
    }
  }
  .edit-btn-box{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    .btn{
      font-size: 0.14rem;
      line-height: 0.45rem;
      text-align: center;
      color:#333;
      &:nth-of-type(1){
        border-bottom: 0.01rem solid #e6e6e6;
      }
      &:nth-of-type(2){
        border-bottom: 0.07rem solid #e6e6e6;
      }
    }
  }
  .van-popup--bottom{
    border-radius: 0.06rem 0.06rem 0 0;
  }
  .van-collapse-item__title{
    padding: 0 !important;
    border-bottom: 0.01rem solid var(--van-gray-2);
    align-items:center;
  }
  .van-collapse-item__content{
    padding: 0 !important;
  }
  .van-cell__right-icon{
    padding-right: 0.07rem;
  }
  .van-floating-bubble{
    background:#009688 !important;
    width: 0.4rem !important;
    height: 0.4rem !important;
    .custom-content{
      width: 100%;
      height: 100%;
      position: relative;
      .van-icon-list-switch{
        position: absolute;
        top: 0.05rem;
        left: 0.05rem;
        width: 0.3rem !important;
        height: 0.3rem !important;
        font-size: 0.3rem !important;
      }
      .van-popover__wrapper{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0
      }
    }
  }
</style>
