import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import axios from './api/request'
import { Tree } from 'ant-design-vue'
// 注册组件
import checkedSelect from './components/checkedSelect.vue'
import selectCC from './components/selectCustomerContacts.vue'
import usersTree from './components/allUsersTree.vue'
import commentsList from './components/commentsList.vue'
import report from './components/reportDetails.vue'
// 引入组件样式
import 'vant/lib/index.css'
import 'ant-design-vue/dist/reset.css'

createApp(App).use(store).use(router).use(Vant).use(Tree)
  .component('checkedSelect', checkedSelect)
  .component('selectCC', selectCC)
  .component('usersTree', usersTree)
  .component('commentsList', commentsList)
  .component('report', report)
  .provide('$axios', axios).mount('#app')
