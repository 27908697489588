import axios from 'axios'
import { showToast } from 'vant'
const url = window.location.href
const getParameter = (str, key) => {
  const regExp = new RegExp('^.*[?&]' + key + '=([^&=?]*)&?.*$', '')
  const parameter = decodeURI(str).replace(regExp, '$1')
  return parameter
}
const openid = getParameter(url, 'OpenId')
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
  // timeout: 5000
})
// service.defaults.responseType = 'json'
// service.defaults.headers.post['Content-Type'] = 'application/json'
// 请求拦截器
service.interceptors.request.use(
  config => {
    if (localStorage.getItem('token')) {
      config.headers = Object.assign({
        'Plus-Authorization': localStorage.getItem('token')
      }, config.headers)
    }
    return config
  },
  error => {
    return Promise.reject(error) // 在axios的catch中写入报错后回调
  }
)
// 响应拦截器
service.interceptors.response.use(
  response => {
    // 请求成功处理
    // console.log(response.data)
    // console.log(response)
    if (!response.data.isSuccess) {
      if (response.data.Code === 403) {
        // token过期
        if (localStorage.getItem('token')) {
          axios.get(`${process.env.VUE_APP_BASE_API}/api/LoginAPI/GetUserLoginByOpenid?OpenId=${localStorage.getItem('OpenId')}`)
            .then(res => {
              localStorage.setItem('token', res.data.data.s_token)
              localStorage.setItem('userInfo', JSON.stringify(res.data.data))
              // location.reload()
              showToast('您当前账号有多个移动端在线-01')
            })
        } else {
          localStorage.setItem('OpenId', openid)
          axios.get(`${process.env.VUE_APP_BASE_API}/api/LoginAPI/GetUserLoginByOpenid?OpenId=${openid}`)
            .then(res => {
              localStorage.setItem('saveUrl', window.location.href)
              window.location.href = 'https://plusm.cicayun.com/reload1.html'
            })
        }
      }
      showToast(response.data.Message)
      // return Promise.reject(new Error(response.data.Message))
      return response
      // console.log(response.data.Message)
    } else {
      return response
    }
  },
  error => {
    // const originalRequest = error.config
    // console.log(originalRequest)
    console.log('err', error.response)
    if (localStorage.getItem('token')) {
      axios.get(`${process.env.VUE_APP_BASE_API}/api/LoginAPI/GetUserLoginByOpenid?OpenId=${localStorage.getItem('OpenId')}`)
        .then(res => {
          localStorage.setItem('token', res.data.data.s_token)
          localStorage.setItem('userInfo', JSON.stringify(res.data.data))
          // location.reload()
          showToast('您当前账号有多个移动端在线-03')
        })
    } else {
      const saveUrl = window.location.href
      // localStorage.setItem('saveUrl', saveUrl)
      window.location.href = 'https://plusm.cicayun.com/reload1.html?saveUrl=' + saveUrl
    }
    return Promise.reject(error)
  }
)

export default service
