<template>
  <div class="cbody">
    <div class="csearchbox">
      <div class="inputbox">
        <input type="text" v-if="props.datatype==1" v-model="keyword1" placeholder="关键字" @input="search"/>
        <input type="text" v-if="props.datatype==2" v-model="keyword2" placeholder="关键字" @input="search"/>
      </div>
    </div>
    <div class="ctagbox">
      <template v-if="props.datatype === 1">
        <div class="tagcheckedbox">
          <div class="span" v-for="(item,index) in checkedCompanysList" :key="index">{{item.text}}<span @click="removeChecked(index)">X</span></div>
          <div class="all-clear" v-if="checkedCompanysList.length > 0" @click="removeCheckedAll(index)">清空选中</div>
        </div>
        <div class="tagbox">
          <template v-for="(item,index) in copycompanysList" :key="index">
            <span v-if="!checkedCompanysStringArr.includes(item.text)" @click="checked(index)">{{item.text}}</span>
            <span v-else @click="rechecked(index)" class="active">{{item.text}}</span>
          </template>
        </div>
      </template>
      <template v-if="props.datatype === 2">
        <div class="tagcheckedbox">
          <div class="span" v-for="(item,index) in checkedAllUsersList" :key="index">{{item.name}}<span @click="removeChecked(index)">X</span></div>
          <div class="all-clear" v-if="checkedAllUsersList.length > 0" @click="removeCheckedAll(index)">清空</div>
        </div>
        <div class="tagbox">
          <template v-for="(item,index) in copyAllUsersList" :key="index">
            <span v-if="!checkedAllUsersStringArr.includes(item.name)" @click="checked(index)">{{item.name}}</span>
            <span v-else @click="rechecked(index)" class="active">{{item.name}}</span>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, defineExpose, defineProps, onMounted } from 'vue'
import {
  PlusCustomerCompanysGetAvailable,
  UsersGetAllUsers
} from '@/api/api'

const props = defineProps({
  datatype: {
    type: String,
    default: ''
  },
  checkedCompanys: {
    type: Array,
    default: () => []
  },
  checkedAllUsers: {
    type: Array,
    default: () => []
  }
})
// 客户公司
const keyword1 = ref('')
// 员工/联系人
const keyword2 = ref('')
// 客户公司简称
const companysList = ref()
const copycompanysList = ref([])
const checkedCompanys = ref(props.checkedCompanys)
const checkedCompanysList = ref([])
const checkedCompanysStringArr = ref([])
const checkedCompanysListValue = ref([])
const checkedCompanysString = ref()
// 公司员工
const allUsersList = ref()
const copyAllUsersList = ref([])
const checkedAllUsers = ref(props.checkedAllUsers)
const checkedAllUsersList = ref([])
const checkedAllUsersStringArr = ref([])
const checkedAllUsersListValue = ref([])
const checkedAllUsersString = ref()

onMounted(() => {
  if (localStorage.getItem('companysList')) {
    const arrs = JSON.parse(localStorage.getItem('companysList'))
    companysList.value = arrs
    copycompanysList.value = arrs.data
  } else {
    PlusCustomerCompanysGetAvailable().then(res => {
      const arr = res.data.data.map((x) => {
        return {
          text: x.customer_company,
          abbr: x.customer_code,
          value: x.id,
          s_companyotherName: x.s_companyotherName,
          s_companyotherNamecode: x.s_companyotherNamecode,
          qualificationName: x.qualificationName,
          typeName: x.typeName
        }
      })
      const arrs = { data: [...arr] }
      companysList.value = arrs
      copycompanysList.value = arrs.data
      localStorage.setItem('companysList', JSON.stringify(arrs))
    })
  }
  if (localStorage.getItem('AllUsers')) {
    const arrs = JSON.parse(localStorage.getItem('AllUsers'))
    allUsersList.value = arrs
    copyAllUsersList.value = arrs.data
  } else {
    UsersGetAllUsers().then(res => {
      const arrs = { data: [...res.data.data] }
      allUsersList.value = arrs
      copyAllUsersList.value = arrs.data
      localStorage.setItem('AllUsers', JSON.stringify(arrs))
    })
  }
  // 初始化
  checkedCompanysList.value = copycompanysList.value.filter(obj => checkedCompanys.value.includes(obj.value))
  checkedCompanysStringArr.value = checkedCompanysList.value.map(item => item.text)
  checkedCompanysString.value = checkedCompanysStringArr.value.join(',')

  checkedAllUsersList.value = copyAllUsersList.value.filter(obj => checkedAllUsers.value.includes(obj.id))
  checkedAllUsersStringArr.value = checkedAllUsersList.value.map(item => item.name)
  checkedAllUsersString.value = checkedAllUsersStringArr.value.join(',')

  getValue()
})
// 选中
const checked = (index) => {
  if (props.datatype === 1) {
    checkedCompanysList.value.push(copycompanysList.value[index])
    checkedCompanysStringArr.value.push(copycompanysList.value[index].text)
    // 去重
    checkedCompanysList.value = [...new Set(checkedCompanysList.value)]
    checkedCompanysStringArr.value = [...new Set(checkedCompanysStringArr.value)]
    // 转格式
    checkedCompanysString.value = checkedCompanysStringArr.value.join(',')
  } else if (props.datatype === 2) {
    checkedAllUsersList.value.push(copyAllUsersList.value[index])
    checkedAllUsersStringArr.value.push(copyAllUsersList.value[index].name)
    // 去重
    checkedAllUsersList.value = [...new Set(checkedAllUsersList.value)]
    checkedAllUsersStringArr.value = [...new Set(checkedAllUsersStringArr.value)]
    // 转格式
    checkedAllUsersString.value = checkedAllUsersStringArr.value.join(',')
  }
  getValue()
}
// 取消选中
const rechecked = (index) => {
  if (props.datatype === 1) {
    const ind1 = checkedCompanysList.value.findIndex(item => item.value === copycompanysList.value[index].value)
    const ind2 = checkedCompanysStringArr.value.findIndex(item => item === copycompanysList.value[index].text)
    // 删除
    checkedCompanysList.value.splice(ind1, 1)
    checkedCompanysStringArr.value.splice(ind2, 1)
    // 转格式
    checkedCompanysString.value = checkedCompanysStringArr.value.join(',')
  } else if (props.datatype === 2) {
    const ind1 = checkedAllUsersList.value.findIndex(item => item.value === copyAllUsersList.value[index].value)
    const ind2 = checkedAllUsersStringArr.value.findIndex(item => item === copyAllUsersList.value[index].name)
    // 删除
    checkedAllUsersList.value.splice(ind1, 1)
    checkedAllUsersStringArr.value.splice(ind2, 1)
    // 转格式
    checkedAllUsersString.value = checkedAllUsersStringArr.value.join(',')
  }
  getValue()
}
// 取值
const getValue = () => {
  if (props.datatype === 1) {
    checkedCompanysListValue.value = []
    checkedCompanysList.value.forEach(e => {
      checkedCompanysStringArr.value.forEach(i => {
        if (i === e.text) {
          checkedCompanysListValue.value.push(e.value)
        }
      })
    })
    checkedCompanysListValue.value = checkedCompanysListValue.value.join(',')
  } else if (props.datatype === 2) {
    checkedAllUsersListValue.value = []
    checkedAllUsersList.value.forEach(e => {
      checkedAllUsersStringArr.value.forEach(i => {
        if (i === e.name) {
          checkedAllUsersListValue.value.push(e.id)
        }
      })
    })
    checkedAllUsersListValue.value = checkedAllUsersListValue.value.join(',')
  }
}
// 清除
const removeChecked = (index) => {
  if (props.datatype === 1) {
    checkedCompanysList.value.splice(index, 1)
    checkedCompanysStringArr.value.splice(index, 1)
    checkedCompanysString.value = checkedCompanysStringArr.value.join(',')
  } else if (props.datatype === 2) {
    checkedAllUsersList.value.splice(index, 1)
    checkedAllUsersStringArr.value.splice(index, 1)
    checkedAllUsersString.value = checkedAllUsersStringArr.value.join(',')
  }
  getValue()
}
// 清除全部
const removeCheckedAll = () => {
  if (props.datatype === 1) {
    checkedCompanysList.value = []
    checkedCompanysStringArr.value = []
    checkedCompanysString.value = ''
  } else if (props.datatype === 2) {
    checkedAllUsersList.value = []
    checkedAllUsersStringArr.value = []
    checkedAllUsersString.value = ''
  }
  getValue()
}
// 搜索
const search = () => {
  if (props.datatype === 1) {
    const arr = companysList.value.data
    const searchlist1 = arr.filter(item => item.text.includes(keyword1.value))
    const searchlist2 = arr.filter(item => item.abbr.includes(keyword1.value.toUpperCase()))
    const searchlist = [...searchlist1, ...searchlist2]
    if (keyword1.value.length !== 0) {
      copycompanysList.value = reactive(searchlist)
    } else {
      copycompanysList.value = reactive(companysList.value.data)
    }
  } else if (props.datatype === 2) {
    const arr = allUsersList.value.data
    const searchlist1 = arr.filter(item => item.name.includes(keyword2.value))
    // eslint-disable-next-line array-callback-return
    const newarr = arr.filter(item => item.note !== null)
    const searchlist2 = newarr.filter(item => item.note.includes(keyword2.value.toUpperCase()))
    const searchlist = [...searchlist1, ...searchlist2]
    if (keyword2.value.length !== 0) {
      copyAllUsersList.value = reactive(searchlist)
    } else {
      copyAllUsersList.value = reactive(allUsersList.value.data)
    }
  }
}
// 对外暴露
defineExpose({
  checkedCompanysListValue,
  checkedCompanysString,
  checkedAllUsersListValue,
  checkedAllUsersString
})

</script>
<style lang="scss">
  .cbody{
    width: 100%;
    height: 80vh;
    background:#ebebeb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .csearchbox{
      width: 100%;
      height: auto;
      background: #fff;
      padding: 0.2rem !important;
      box-sizing: border-box;
      .inputbox{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:nth-of-type(2){
          margin-top: 0.1rem;
        }
        input{
          height: 0.32rem;
          width: 100%;
          font-size: 0.14rem;
          border:0.01rem solid #e6e6e6;
          box-sizing: border-box;
          border-radius: 0.05rem;
          padding: 0 0.08rem;
        }
      }
    }
    .ctagbox{
      width: 100%;
      height: calc(80vh - 0.72rem);
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .tagcheckedbox{
        width: 100%;
        height:auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        padding: 0 0 0 0.2rem;
        box-sizing: border-box;
        .span{
          padding: 0.05rem 0 0.05rem 0.08rem;
          background: #ffb000;
          color:#fff;
          font-size: 0.14rem;
          position: relative;
          margin:0 0.05rem 0.1rem 0;
          span{
            padding: 0.05rem 0.08rem 0.05rem 0.05rem;
          }
        }
        .all-clear{
          font-size: 0.14rem;
          color:#fff;
          padding: 0.05rem 0.08rem;
          background: #ff5722;
          margin:0 0.05rem 0.1rem 0;
        }
      }
      .tagbox{
        width: 100%;
        height:auto;
        overflow: auto;
        padding: 0.2rem 0 0.2rem 0.2rem;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        span{
          padding: 0.05rem 0.26rem 0.05rem 0.12rem;
          // border: 0.01rem solid #999;
          border-radius: 0.04rem;
          color:#333;
          font-size: 0.15rem;
          position: relative;
          margin:0 0.07rem 0.1rem 0;
          &.active{
            background:#5fb878;
            // border: 0.01rem solid #5fb878;
            position: relative;
            color:#fff;
            &::after{
              content: "✔";
              position: absolute;
              right: 0.1rem;
              top: 50%;
              font-size: 0.14rem;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
</style>
