<template>
  <div class="home">
    <div class="searchbox">
      <div class="inputbox">
        <!-- <input type="text" v-model="themeKeyword" placeholder="沟通主题"/> -->
        <van-field
          v-model="themeKeyword"
          clearable
          clear-trigger="always"
          name="themeKeyword"
          autocomplete="off"
          placeholder="沟通主题"
          @clear="search()"
        />
        <van-field
          v-model="dateStr"
          clearable
          clear-trigger="always"
          readonly
          :right-icon="dateStr ? 'clear' : null"
          @click-right-icon.stop="clearContent()"
          name="dateStr"
          autocomplete="off"
          placeholder="报告日期"
          @click="showDatePopup"
          @clear="search()"
        />
        <!-- <input type="text" v-model="dateStr" readonly placeholder="报告日期" @click="showDatePopup"/> -->
      </div>
      <button class="btn" @click="search">搜索</button>
    </div>
    <div class="listbox">
      <div class="listarr">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="list" v-for="(item,index) in listData" :key="index" @click="pagePush(item.id)">
            <div class="title" v-if="item.content!==''"><p>{{item.content}}</p></div>
            <div class="listitem" v-if="item.createrName!==''"><p>创建人员：{{item.createrName}}</p></div>
            <div class="listitem" v-if="item.createTime!==''"><p>创建日期：{{item.createTime}}</p></div>
            <div class="listitem" v-if="item.businessStageName!==''"><p>商机阶段：{{item.businessStageName}}</p></div>
            <div class="listitem" v-if="item.budgetTypeName!==''"><p>预算分类：{{item.budgetTypeName}}</p></div>
            <div class="listitem" v-if="item.budget!==''"><p>预算金额：{{item.budget}}</p></div>
            <div class="listitem" v-if="item.customerCompanyNames!==''"><p>客户公司：{{item.customerCompanyNames}}</p></div>
            <div class="listitem" v-if="item.updaterName!==''"><p>更新人员：{{item.updaterName}}</p></div>
            <div class="listitem" v-if="item.updateTime!==''"><p>最近维护：{{item.updateTime}}</p></div>
            <div class="btn-rows">
              <button class="editbtn" @click.stop="themesedit(item.id)">修改</button>
              <button class="delbtn" @click.stop="themesdel(item.content,item.id)">删除</button>
            </div>
          </div>
        </van-list>
      </div>
      <router-link class="adda" to="/addBCT"><button class="addbtn" @click="addbct">点击新增主题</button></router-link>
    </div>
    <!-- 日历 -->
    <van-calendar v-model:show="dateshow" type="range" :min-date="minDate" :max-date="maxDate" :allow-same-day="true" :show-confirm="false" @confirm="onConfirm" />
  </div>
  <!-- 修改主题弹框 -->
  <van-dialog v-model:show="show" show-cancel-button @confirm="editSubmit" confirm-button-color="#009688">
    <div class="dialogMessageBox">
      <van-form>
        <van-cell-group inset>
          <van-field
            v-model="title"
            name="title"
            label="主题名称："
            autocomplete="off"
            rows="2"
            autosize
            type="textarea"
            placeholder="主题名称（必填）"
            :rules="[{ required: true, message: '请填写主题名称' }]"
          />
          <van-field
            v-model="note"
            label="需求简述："
            name="note"
            rows="2"
            autosize
            type="textarea"
            autocomplete="off"
            placeholder="项目/需求简述"
          />
        </van-cell-group>
      </van-form>
    </div>
  </van-dialog>
</template>

<script setup>

import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { BusinessCommunicationThemesGetList, BusinessCommunicationThemesDel, BusinessCommunicationGet, BusinessCommunicationThemesEdit } from '@/api/api'
import { showToast, showConfirmDialog } from 'vant'
const router = useRouter()
const themeKeyword = ref('')
const dateStr = ref('')
const startDate = ref('')
const endDate = ref('')
const dateshow = ref(false)
const listData = ref([])
const minDate = new Date(new Date().getFullYear() - 2, new Date().getMonth() + 1, new Date().getDate())
const maxDate = new Date(new Date().getFullYear() + 1, new Date().getMonth() + 1, new Date().getDate())
const pageIndex = ref(1)
const pageSize = ref(10)

const show = ref(false)
const title = ref('')
const note = ref('')
const themesid = ref('')

const loading = ref(false)
const finished = ref(true)
// 获取列表
onMounted(() => {
  listdata()
})
// 触底
const onLoad = () => {
  // console.log('触底')
  loading.value = true
  pageIndex.value++
  listdata()
}
// 搜索
const search = () => {
  // console.log('搜索')
  pageIndex.value = 1
  listData.value = []
  listdata()
}
// 请求列表数据
const listdata = () => {
  const data = {
    condition: {
      themeKeyword: themeKeyword.value,
      startDate: startDate.value,
      endDate: endDate.value
    },
    pager: {
      index: pageIndex.value,
      size: pageSize.value,
      sortField: 'updateTime',
      sortType: 'DESC',
      sort: 'updateTime DESC'
    }
  }
  // fetchPost('/api/BusinessCommunicationThemes/GetList', data)
  //   .then(data => console.log('fetchPost', data))
  //   .catch(error => console.error(error))

  BusinessCommunicationThemesGetList(data).then((res) => {
    if (res.data.isSuccess) {
      const totalPage = Math.ceil(res.data.dataCount / pageSize.value)
      const listArr = listData.value
      listArr.push(...res.data.data)
      const updatedArr = listArr.map(item => {
        return {
          ...item,
          createTime: item.createTime.slice(0, 10),
          updateTime: item.updateTime.split('').map(c => c === '/' ? '-' : c).join('')
        }
      })
      listData.value = updatedArr
      loading.value = false
      if (totalPage < pageIndex.value || totalPage === pageIndex.value) {
        finished.value = true
      } else {
        finished.value = false
      }
    } else {
      showToast(res.data.message)
    }
  })
}
// 修改主题
const themesedit = (id) => {
  show.value = true
  const params = {
    id
  }
  themesid.value = id
  BusinessCommunicationGet(params).then((res) => {
    if (res.data.isSuccess) {
      title.value = res.data.data.title
      note.value = res.data.data.note
    } else {
      showToast(res.data.message)
    }
  })
}
const editSubmit = () => {
  const data = {
    title: title.value,
    note: note.value,
    id: themesid.value
  }
  BusinessCommunicationThemesEdit(data).then((res) => {
    if (res.data.isSuccess) {
      showToast(res.data.message)
      const arr = listData.value.map(item => {
        if (item.id === themesid.value) {
          return { ...item, content: title.value, note: note.value }
        }
        return item
      })
      listData.value = arr
      // listdata()
    } else {
      showToast(res.data.message)
    }
  })
}
// 删除主题
const themesdel = (name, id) => {
  showConfirmDialog({
    message: `是否删除主题【${name}】`,
    confirmButtonColor: '#be3434',
    confirmButtonText: '删除'
  })
    .then(() => {
      const params = {
        id
      }
      BusinessCommunicationThemesDel(params).then((res) => {
        if (res.data.isSuccess) {
          showToast(res.data.message)
          let arr = listData.value
          arr = arr.filter(item => item.id !== id)
          listData.value = arr
        } else {
          showToast(res.data.message)
        }
      })
    })
    .catch(() => {
    // on cancel
    })
}
// popup展示
const showDatePopup = () => {
  dateshow.value = true
}
// 日期选择
const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
const onConfirm = (values) => {
  const [start, end] = values
  dateshow.value = false
  dateStr.value = `${formatDate(start)} 至 ${formatDate(end)}`
  startDate.value = `${formatDate(start)}`
  endDate.value = `${formatDate(end)}`
}
// 跳转
const pagePush = (id) => {
  router.push({
    path: '/BCTlistDetails',
    query: { id }
  })
}
// 清除内容
const clearContent = (refname) => {
  dateStr.value = ''
  startDate.value = ''
  endDate.value = ''
  search()
}
</script>
<style lang="scss">
  .home{
    width: 100%;
    height: 100vh;
    background:#ebebeb;
    .searchbox{
      width: 100%;
      height: 0.95rem;
      background: #fff;
      border-bottom: 0.01rem solid #e6e6e6;
      padding: 0.1rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .inputbox{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &:nth-of-type(2){
          margin-top: 0.1rem;
        }
        .van-cell{
          padding:0 !important;
          &:after{
            border:0;
          }
          .van-field__body{
            height: 0.32rem;
            width: 100%;
            border:0.01rem solid #e6e6e6;
            box-sizing: border-box;
            border-radius: 0.05rem;
            padding: 0 0.1rem;
            input{
              font-size: 0.14rem;
            }
          }
        }
      }
      .btn{
        width: 1rem;
        height: 0.72rem;
        background: #009688;
        color: #fff;
        border: none;
        border-radius: 0.05rem;
        margin-left: 0.1rem;
        font-size: 0.15rem;
      }
    }
    .listbox{
      width: 100%;
      height: calc(100vh - 0.95rem - 0.4rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0.1rem;
      box-sizing: border-box;
      .listarr{
        width: 100%;
        height: calc(100% - 0.4rem);
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .van-list{
          width: 100%;
        }
        .list{
          width: 100%;
          height: auto;
          background:#fff;
          border-radius: 0.1rem;
          border:0.01rem solid #e6e6e6;
          padding: 0.1rem;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-bottom:0.1rem;
          position: relative;
          .title{
            width: 100%;
            height: auto;
            margin-bottom: 0.08rem;
            p{
              font-size: 0.18rem;
              font-weight: bold;
              line-height: 0.24rem;
              text-align: justify;
              color:#333;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
            }
          }
          .listitem{
            width: 100%;
            height: auto;
            p{
              font-size: 0.13rem;
              line-height: 0.2rem;
              text-align: justify;
              color:#333;
            }
          }
          .btn-rows{
            width: 100%;
            height: 0.3rem;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 0.1rem;
            box-sizing: border-box;
            position: absolute;
            bottom: 0.05rem;
            .editbtn{
              width: 0.4rem;
              height: 0.3rem;
              background: none;
              color: #009688;
              border: none;
              border-radius: 0.04rem;
              font-size: 0.15rem;
              font-weight: bold;
            }
            .delbtn{
              width: 0.4rem;
              height: 0.3rem;
              background: none;
              color: #be3434;
              border: none;
              border-radius: 0.04rem;
              font-size: 0.15rem;
              font-weight: bold;
            }
          }
        }
      }
      .adda{
        width: 2.6rem;
        height: 0.35rem;
        display: flex;
        margin-top: 0.14rem;
        .addbtn{
          width: 100%;
          height: 100%;
          background: #009688;
          color: #fff;
          border: none;
          border-radius: 0.04rem;
          font-size: 0.15rem;
        }
      }
    }
  }
  .van-dialog__message{
    font-size: 0.17rem !important;
  }
  .van-button--large{
    font-size: 0.17rem !important;
  }
</style>
