import { createRouter, createWebHashHistory } from 'vue-router'
import BCTlist from '../views/BCTlist.vue'
import addBCT from '../views/addBCT.vue'
import BCTlistDetails from '../views/BCTlistDetails.vue'
import addBCTReport from '../views/addBCTReport.vue'
import bindOpenIdPage from '../views/bindOpenIdPage.vue'
import msgList from '../views/msgList.vue'

const routes = [
  {
    path: '/BusinessCommunicationThemes',
    name: 'home',
    component: BCTlist,
    meta: {
      title: '客户沟通主题列表'
    }
  },
  {
    path: '/addBCT',
    name: 'addBCT',
    component: addBCT,
    meta: {
      title: '新增主题'
    }
  },
  {
    path: '/BCTlistDetails',
    name: 'BCTlistDetails',
    component: BCTlistDetails,
    meta: {
      title: '沟通主题详情'
    }
  },
  {
    path: '/addBCTReport',
    name: 'addBCTReport',
    component: addBCTReport,
    meta: {
      title: '新增报告编辑报告'
    }
  },
  {
    path: '/bindOpenIdPage',
    name: 'bindOpenIdPage',
    component: bindOpenIdPage,
    meta: {
      title: '绑定确认'
    }
  },
  {
    path: '/msgList',
    name: 'msgList',
    component: msgList,
    meta: {
      title: '消息列表'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  // history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
