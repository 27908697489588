<template>
  <div class="home">
    <div class="page-toolbar">
      <div class="toolbar-top-right">
        <p @click="allMsgRead">全部已读</p>
        <van-icon name="search" size="20" @click="searchboxShow = !searchboxShow" />
      </div>
      <div class="toolbar-bottom">
        <div class="img-box" @click="checkMsgtype('')">
          <img src="../assets/images/followAt1.png" alt="">
          <p>所有消息</p>
        </div>
        <div class="img-box" @click="checkMsgtype('2')">
          <img src="../assets/images/followAt.png" alt="">
          <p>评论at</p>
        </div>
      </div>
    </div>
    <div :class="{'searchbox':searchboxShow===false, 'searchbox showSearch':searchboxShow===true}">
      <van-icon v-show="searchboxShow" name="revoke" @click="searchboxShow = !searchboxShow" />
      <div class="searchbox-content">
        <div class="inputbox">
          <van-field
            v-model="keyword"
            clearable
            clear-trigger="always"
            name="keyword"
            autocomplete="off"
            placeholder="关键字"
            @clear="search('clear')"
          />
          <van-field
            v-model="dateStr"
            clearable
            clear-trigger="always"
            readonly
            :right-icon="dateStr ? 'clear' : null"
            @click-right-icon.stop="clearContent()"
            name="dateStr"
            autocomplete="off"
            placeholder="发信日期"
            @click="showDatePopup"
            @clear="search('clear')"
          />
        </div>
        <button class="btn" @click="search('click')">搜索</button>
      </div>
    </div>
    <div class="list-containers">
      <div class="list-arr-box">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="list" v-for="(item,index) in listData" :key="index" @click="confirmDialog(item.id)">
            <div v-if="item.readState == 1" class="van-badge van-badge--top-right van-badge--dot van-badge--fixed"></div>
            <div class="list-top">
              <p>{{item.senderName}}</p>
              <span>{{item.sendTime}}</span>
            </div>
            <div class="list-bottom">
              <!-- <p>客户沟通报告的“跟进”中，有人提到你，请及时查看</p> -->
              <p v-html="item.content"></p>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <van-calendar v-model:show="dateshow" type="range" :min-date="minDate" :max-date="maxDate" :allow-same-day="true" :show-confirm="false" @confirm="onConfirm" />
  </div>
  <van-popup
    v-model:show="popupShow"
    closeable
    :style="{ width: '70%' }"
  >
    <div class="popup-html-class" v-html="popupHtml"></div>
  </van-popup>
</template>

<script setup>

import { ref, onMounted } from 'vue'
// import { useRouter } from 'vue-router'
import {
  InnerMessagesGetMine,
  innerMessagesRead,
  innerMessagesMultiRead
} from '@/api/api'
import { showToast, showConfirmDialog } from 'vant'
import { message } from 'ant-design-vue'
// const router = useRouter()
// 搜索栏显示隐藏
const searchboxShow = ref(false)
// 日期选择显示隐藏
const dateshow = ref(false)
const minDate = new Date(new Date().getFullYear() - 2, new Date().getMonth() + 1, new Date().getDate())
const maxDate = new Date(new Date().getFullYear() + 1, new Date().getMonth() + 1, new Date().getDate())
// 搜索条件
const keyword = ref('')
const dateStr = ref('')
const startDate = ref('')
const endDate = ref('')
const msgType = ref('')
// 列表内容
const listData = ref([])
// 列表参数
const pageIndex = ref(1)
const pageSize = ref(30)
const loading = ref(false) // 加载动画
const finished = ref(true) // 关闭上滑加载
const popupShow = ref(false) // 消息确认弹窗
const popupHtml = ref('') // 消息确认弹窗内容
// 获取列表
onMounted(() => {
  message.destroy()
  listdata()
})
// 触底
const onLoad = () => {
  // console.log('触底')
  loading.value = true
  pageIndex.value++
  listdata()
}
// 搜索
const search = (type) => {
  // console.log('搜索')
  if (type === 'clear') {
    pageIndex.value = 1
    listData.value = []
    listdata()
  } else {
    if (keyword.value !== '' || dateStr.value !== '') {
      searchboxShow.value = false
      pageIndex.value = 1
      listData.value = []
      listdata()
    }
  }
}
// 请求列表数据
const listdata = () => {
  const data = {
    condition: {
      keyword: keyword.value,
      startDate: startDate.value,
      endDate: endDate.value,
      type: msgType.value * 1
    },
    pager: {
      index: pageIndex.value,
      size: pageSize.value,
      sortField: 'updateTime',
      sortType: 'DESC',
      sort: 'updateTime DESC'
    }
  }
  InnerMessagesGetMine(data).then((res) => {
    // console.log(res.data)
    if (res.data.isSuccess) {
      const totalPage = Math.ceil(res.data.dataCount / pageSize.value)
      const listArr = listData.value
      listArr.push(...res.data.data)
      const newlistArr = listArr.map(item => {
        const originalString = item.content
        const newHrefValue = `${process.env.VUE_APP_BASE_API}${getHrefValue(item.content)}`
        return { ...item, content: originalString.replace(/href='[^']*'/g, `href='${newHrefValue}'`) }
      })
      listData.value = newlistArr
      loading.value = false
      if (totalPage < pageIndex.value || totalPage === pageIndex.value) {
        finished.value = true
      } else {
        finished.value = false
      }
    } else {
      showToast(res.data.message)
    }
  })
}
// 选择消息类型
const checkMsgtype = (type) => {
  msgType.value = type
  listData.value = []
  listdata()
}
// 消息详情
const confirmDialog = (id) => {
  // console.log(id)
  const obj = listData.value.filter(item => item.id === id)[0]
  // console.log(obj.content)
  if (obj.readState === 1) {
    innerMessagesRead({ id }).then((res) => {
      // console.log(res.data)
      const arr = listData.value
      arr[arr.findIndex(item => item.id === id)] = {
        ...obj,
        readState: 2,
        readStateName: '已读'
      }
      listData.value = arr
      if (!res.data.isSuccess) {
        showToast(res.data.message)
      }
    })
  }

  if (obj.type === 2) {
    // popupShow.value = true
    // popupHtml.value = obj.content
    // showConfirmDialog({
    //   message: '客户沟通报告的“跟进”中，有人提到你，请及时查看',
    //   confirmButtonText: '跳转查看'
    // })
    //   .then(() => {
    // on confirm
    // const id = getParameter(obj.content, 'id')
    // const report = getContentBefore(getParameter(obj.content, 'report'))
    // router.push({
    //   path: '/BCTlistDetails',
    //   query: { id, report }
    // })
    // })
    // .catch(() => {
    // // on cancel
    // })
  }
}
const getHrefValue = (str) => {
  const hrefRegex = /href='([^"]+)'/
  const match = hrefRegex.exec(str)
  return match ? match[1] : null
}
// const getParameter = (str, key) => {
//   const regExp = new RegExp('^.*[?&]' + key + '=([^&=?]*)&?.*$', '')
//   const parameter = decodeURI(str).replace(regExp, '$1')
//   return parameter
// }
// const getContentBefore = (str, at = "'") => {
//   const matchResult = str.match(/^.*(?=')/)
//   return matchResult ? matchResult[0] : ''
// }

// 全部消息已读
const allMsgRead = () => {
  const arr = listData.value
  const ids = arr.filter(obj => obj.readState === 1).map(item => item.id)
  if (ids.length > 0) {
    showConfirmDialog({
      message:
      '是否将消息全部标记为已读'
    })
      .then(() => {
      // on confirm
        innerMessagesMultiRead({ list: ids }).then((res) => {
          if (res.data.isSuccess) {
            showToast('已全部标记为已读')
            const updatedArr = arr.map(item => {
              return {
                ...item,
                readState: 2,
                readStateName: '已读'
              }
            })
            listData.value = updatedArr
          } else {
            showToast(res.data.message)
          }
        })
      })
      .catch(() => {
      // on cancel
      })
  } else {
    showToast('没有未读消息')
  }
}

// 日期选择
const showDatePopup = () => {
  dateshow.value = true
}
const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
const onConfirm = (values) => {
  const [start, end] = values
  dateshow.value = false
  dateStr.value = `${formatDate(start)} 至 ${formatDate(end)}`
  startDate.value = `${formatDate(start)}`
  endDate.value = `${formatDate(end)}`
}
// 清除内容
const clearContent = (refname) => {
  dateStr.value = ''
  startDate.value = ''
  endDate.value = ''
  search()
}
</script>
<style lang="scss" scoped>
  .home{
    width: 100%;
    height: 100vh;
    background:#ebebeb;
    .page-toolbar{
      width: 100%;
      height: 1rem;
      background: #fff;
      border-bottom: 0.01rem solid #e6e6e6;
      padding: 0.1rem;
      box-sizing: border-box;
      .toolbar-top-right{
        float: right;
        width: 0.8rem;
        height: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .van-icon{
          font-size: 0.13rem !important;
          background: #666;
          color: #fff;
          border-radius: 50%;
          padding: 0.03rem 0.03rem 0.02rem 0.03rem;
        }
        p{
          font-size: 0.12rem;
          line-height: 0.2rem;
        }
      }
      .toolbar-bottom{
        width: 100%;
        height: 0.6rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .img-box{
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 0.1rem;
          img{
            width: 0.4rem;
            height: auto;
          }
          p{
            font-size: 0.13rem;
            line-height: 0.2rem;
          }
        }
      }
    }
    .searchbox{
      width: 0;
      height: 0;
      background: #fff;
      padding: 0 !important;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      position: fixed;
      top: 0;
      right: 0;
      transition: all 0.3s ease-in-out;
      &.showSearch{
        width: 100%;
        height: 1.23rem;
        padding: 0.1rem !important;
        box-shadow: 0 0 0.07rem 0.01rem rgba(0,0,0,0.25);
        transition: width 0.3s ease-in-out;
      }
      .van-icon{
        font-size: 0.13rem;
        background: #666;
        color: #fff;
        border-radius: 50%;
        padding: 0.02rem 0.03rem 0.03rem 0.03rem;
        margin-bottom: 0.04rem;
      }
      .searchbox-content{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

      }
      .inputbox{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &:nth-of-type(2){
          margin-top: 0.1rem;
        }
        .van-cell{
          padding:0 !important;
          &:after{
            border:0;
          }
          .van-field__body{
            height: 0.32rem;
            width: 100%;
            border:0.01rem solid #e6e6e6;
            box-sizing: border-box;
            border-radius: 0.05rem;
            padding: 0 0.1rem;
            input{
              font-size: 0.14rem;
            }
          }
        }
      }
      .btn{
        width: 1rem;
        height: 0.72rem;
        margin-left: 0.1rem;
        background: #009688;
        color: #fff;
        border: none;
        border-radius: 0.05rem;
        font-size: 0.15rem;
      }
    }
    .list-containers{
      width: 100%;
      height: calc(100vh - 1rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.14rem;
      background: #fff;
      .list-arr-box{
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .van-list{
          width: 100%;
        }
        .list{
          width: 100%;
          height: auto;
          background:#fff;
          padding: 0.07rem 0;
          border-bottom: 0.01rem solid #e6e6e6;;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          .van-badge--top-right{
            top: 0.4rem;
            right: 0.06rem;
          }
          .list-top{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
              font-size: 0.15rem;
              line-height: 0.24rem;
              font-weight: bold;
            }
            span{
              font-size: 0.12rem;
              color:#999;
            }
          }
          .list-bottom{
            width: 100%;
            p{
              font-size: 0.14rem;
              color:#666;
              // text-overflow: ellipsis;
              // overflow: hidden;
              // white-space: nowrap;
              // width: 97%;
            }
          }
        }
      }
    }
  }
  .van-dialog__message{
    font-size: 0.17rem !important;
  }
  .van-button--large{
    font-size: 0.17rem !important;
  }
  .popup-html-class{
    width: 100%;
    font-size: 16px;
    padding: 0.55rem 0.1rem 0.35rem 0.1rem;
    box-sizing: border-box;
  }
</style>
