<template>
  <div class="home">
  </div>
</template>

<script setup>

import { onMounted } from 'vue'
import {
  useRouter,
  useRoute
} from 'vue-router'
import { LoginAPIUserLoginGetUserLoginByOpenid } from '@/api/api'
import { showDialog } from 'vant'

const router = useRouter()
const route = useRoute()
onMounted(() => {
  localStorage.setItem('OpenId', route.query.OpenId)
  // 获取列表
  LoginAPIUserLoginGetUserLoginByOpenid({ OpenId: route.query.OpenId }).then(res => {
    // console.log(res)
    if (res.data.isSuccess) {
      localStorage.setItem('token', res.data.data.s_token)
      localStorage.setItem('userInfo', JSON.stringify(res.data.data))
      router.push('/BusinessCommunicationThemes')
    } else {
      showDialog({
        title: 'PLUS账号未绑定微信公众号',
        message: `请先在pc端浏览器打开${process.env.VUE_APP_BASE_API}，登录后在个人中心-个人信息中绑定微信，绑定成功后刷新本页面，或者重新在公众号进入`
      }).then(() => {
        // on close
      })
    }
  })
})
</script>
<style lang="scss">
  .home{
    width: 100%;
    height: 100vh;
    background:#ebebeb;
  }
</style>
