<template>
  <div class="home">
    <van-form @submit="onSubmit" label-width="90" colon :scroll-to-error="true">
      <van-cell-group inset>
        <p class="Ptitle">基本信息</p>
        <div class="box">
          <van-field
            v-model="formdata.customerCompanys"
            name="customerCompanys"
            autocomplete="off"
            label="客户公司简称"
            required
            readonly
            :right-icon="formdata.customerCompanys ? 'clear' : null"
            @click-right-icon.stop="clearContent('customerCompanys')"
            placeholder="客户公司简称"
            @click="showCustomerCompanys"
            :rules="[{ required: true, message: '请选择客户公司简称' }]"
          />
          <van-field
            v-model="formdata.reportDate"
            :default-date="defaultReportDate"
            name="reportDate"
            autocomplete="off"
            label="日期"
            placeholder="日期"
            @click="reportDateShow"
            readonly
            :right-icon="formdata.reportDate ? 'clear' : null"
            @click-right-icon.stop="clearContent('reportDate')"
          />
          <van-field
            v-model="formdata.communicationType"
            name="communicationType"
            @click="showCommunicationType"
            autocomplete="off"
            label="沟通方式"
            placeholder="沟通方式"
            readonly
            :right-icon="formdata.communicationType ? 'clear' : null"
            @click-right-icon.stop="clearContent('communicationType')"
          />
        </div>
        <p class="Ptitle">客户联系人</p>
        <div class="box">
          <van-field
            v-model="formdata.customerContacts"
            name="customerContacts"
            autocomplete="off"
            required
            readonly
            :right-icon="formdata.customerContacts ? 'clear' : null"
            @click-right-icon.stop="clearContent('customerContacts')"
            @click="showCustomerContacts"
            label="客户联系人"
            placeholder="客户联系人"
            :rules="[{ required: true, message: '请选择客户联系人' }]"
          />
        </div>
        <p class="Ptitle">沟通详情</p>
        <div class="box">
          <van-field
            v-model="formdata.content"
            clearable
            clear-trigger="always"
            name="content"
            rows="2"
            autosize
            type="textarea"
            autocomplete="off"
            label="跟进计划/沟通要点"
            placeholder="跟进计划/沟通要点"
            show-word-limit
          />
          <van-field
            v-model="formdata.businessStage"
            name="businessStage"
            autocomplete="off"
            @click="businessStageType"
            label="商机阶段"
            placeholder="商机阶段"
            readonly
            :right-icon="formdata.businessStage ? 'clear' : null"
            @click-right-icon.stop="clearContent('businessStage')"
          />
          <van-field
            v-model="formdata.budgetType"
            name="budgetType"
            autocomplete="off"
            @click="budgetTypeFun"
            label="预算分类"
            placeholder="预算分类"
            readonly
            :right-icon="formdata.budgetType ? 'clear' : null"
            @click-right-icon.stop="clearContent('budgetType')"
          />
          <van-field
            v-model.number="formdata.budget"
            clearable
            clear-trigger="always"
            name="budget"
            autocomplete="off"
            label="预算金额"
            placeholder="预算金额"
            type="number"
          />
          <van-field
            v-model="formdata.keyPointsForOutside"
            clearable
            clear-trigger="always"
            name="keyPointsForOutside"
            rows="2"
            autosize
            type="textarea"
            autocomplete="off"
            label="外部沟通要点"
            placeholder="外部沟通要点"
            show-word-limit
          />
          <van-field
            v-model="formdata.keyPointsForInside"
            clearable
            clear-trigger="always"
            name="keyPointsForInside"
            rows="2"
            autosize
            type="textarea"
            autocomplete="off"
            label="内部沟通要点"
            placeholder="内部沟通要点"
            show-word-limit
          />
        </div>
        <p class="Ptitle">需要支持</p>
        <div class="box">
          <van-field
            v-model="formdata.supporters"
            name="supporters"
            autocomplete="off"
            label="支持人"
            placeholder="支持人"
            @click="showSupporters"
            readonly
            :right-icon="formdata.supporters ? 'clear' : null"
            @click-right-icon.stop="clearContent('supporters')"
          />
          <van-field
            v-model="formdata.supportTime"
            name="supportTime"
            autocomplete="off"
            @click="supportTimeShow"
            label="支持节点"
            placeholder="支持节点"
            readonly
            :right-icon="formdata.supportTime ? 'clear' : null"
            @click-right-icon.stop="clearContent('supportTime')"
          />
          <van-field
            v-model="formdata.supportContent"
            clearable
            clear-trigger="always"
            name="supportContent"
            rows="2"
            autosize
            type="textarea"
            autocomplete="off"
            label="所需支持"
            placeholder="所需支持"
            show-word-limit
          />
          <van-field
            v-model="formdata.note"
            clearable
            clear-trigger="always"
            name="note"
            rows="2"
            autosize
            type="textarea"
            autocomplete="off"
            label="备注说明"
            placeholder="备注说明"
            show-word-limit
          />
        </div>
      </van-cell-group>
      <van-button round block type="primary" native-type="submit" class="submitbtn">保存</van-button>
    </van-form>
  </div>
  <!-- 日历 -->
  <van-calendar v-model:show="showCalendar" :min-date="minDate" :max-date="maxDate" :show-confirm="false" @confirm="onConfirm" />
  <!-- 沟通方式、商机阶段、预算分类 -->
  <van-popup v-model:show="showPicker" position="bottom">
    <van-picker
      :columns="typeArr"
      :value="typeArr[0]"
      @confirm="showPickerType"
      @cancel="showPicker = false"
    />
  </van-popup>
  <!-- 公司简称、支持人 -->
  <van-popup v-model:show="showPicker1" position="bottom">
    <div class="toolbox-btn">
      <div class="cancel" @click="showPicker1=false">取消</div>
      <div class="confirm" @click="showToasts()">确认</div>
    </div>
    <checkedSelect :datatype="datatype" ref="csValue" :checkedCompanys="checkedCompanys" :checkedAllUsers="checkedAllUsers" :key="checkedSelectIndex"></checkedSelect>
  </van-popup>
  <!-- 客户联系人 -->
  <van-popup v-model:show="showPicker2" position="bottom">
    <div class="toolbox-btn">
      <div class="cancel" @click="showPicker2=false">取消</div>
      <div class="confirm" @click="showToasts1()">确认</div>
    </div>
    <selectCC ref="ccValue" :listCheckedId="listCheckedId" :key="selectCCIndex"></selectCC>
  </van-popup>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { CustomerCommunicationReportsGet, CustomerCommunicationReportsSave, CustomerContactsGetDetail, CustomerCommunicationReportsGetLastReport } from '@/api/api'
import { showToast } from 'vant'
const router = useRouter()
const route = useRoute()
// 表单内容
const formdata = ref({
  customerCompanys: '',
  customerCompanysT: '',
  reportDate: '',
  communicationType: '',
  communicationTypeT: '',
  customerContacts: [],
  customerContactsT: [],
  businessStage: '',
  businessStageT: '',
  budgetType: '',
  budgetTypeT: '',
  budget: '',
  content: '',
  keyPointsForOutside: '',
  keyPointsForInside: '',
  supporters: '',
  supportersT: '',
  supportTime: '',
  supportContent: '',
  note: ''
})

const showPicker = ref(false)
const showPicker1 = ref(false)
const showPicker2 = ref(false)
const csValue = ref(null)
const typeArr = ref([])
const typeNum = ref('')
const datatype = ref('')
const checkedCompanys = ref('')
const checkedAllUsers = ref('')
const listCheckedId = ref('')
const ccValue = ref()
const checkedSelectIndex = ref(0)
const selectCCIndex = ref(0)

// 回显信息
onMounted(() => {
  // 编辑报告
  if (route.query.type === '2') {
    reportsGet()
  }
  // 新增报告
  if (route.query.type === '3') {
    reportsAdd()
  }
})
// 编辑
const reportsGet = () => {
  const params = {
    id: route.query.id
  }
  CustomerCommunicationReportsGet(params).then((res) => {
    if (res.data.isSuccess) {
      const data = res.data.data
      // 客户公司简称
      formdata.value.customerCompanys = data.customerCompanysName
      formdata.value.customerCompanysT = data.customerCompanys
      checkedCompanys.value = data.customerCompanys.split(',').map((str) => {
        return parseInt(str)
      })
      // 日期
      formdata.value.reportDate = data.reportDate.slice(0, 10)
      formdata.value.defaultReportDate = data.reportDate.slice(0, 10)
      // 沟通方式
      formdata.value.communicationType = data.communicationTypeName
      formdata.value.communicationTypeT = data.communicationType
      // 客户联系人
      listCheckedId.value = data.customerContactList.map((str) => {
        return parseInt(str.customerContact)
      })
      const result = []
      data.customerContactList.forEach((item, index) => {
        CustomerContactsGetDetail({ id: item.customerContact }).then((r) => {
          if (r.data.isSuccess) {
            result.push(r.data.data.name)
            formdata.value.customerContacts = result.join(',')
          } else {
            showToast(r.data.message)
          }
        })
      })
      formdata.value.customerContactsT = data.customerContactList.map((str) => {
        return { customerContact: str.customerContact, careerExperience: str.careerExperience }
      })
      // 商机阶段
      formdata.value.businessStage = data.businessStageName
      formdata.value.businessStageT = data.businessStage
      // 预算分类
      formdata.value.budgetType = data.budgetTypeName
      formdata.value.budgetTypeT = data.budgetType
      // 预算金额
      formdata.value.budget = data.budget
      // 沟通内容
      formdata.value.content = data.content
      // 外部沟通要点/内部沟通要点
      formdata.value.keyPointsForOutside = data.keyPointsForOutside
      formdata.value.keyPointsForInside = data.keyPointsForInside
      // 支持人员
      formdata.value.supporters = data.supportersName
      formdata.value.supportersT = data.supporters
      checkedAllUsers.value = data.supporters.split(',').map((str) => {
        return parseInt(str)
      })
      // 支持时间
      formdata.value.supportTime = data.supportTime == null ? '' : data.supportTime.slice(0, 10)
      // 支持内容
      formdata.value.supportContent = data.supportContent
      // 备注
      formdata.value.note = data.note
    } else {
      showToast(res.data.msg)
    }
  })
}

// 新增
const reportsAdd = () => {
  const params = {
    id: route.query.theme
  }
  CustomerCommunicationReportsGetLastReport(params).then((res) => {
    if (res.data.isSuccess) {
      const data = res.data.data
      // 客户公司简称
      formdata.value.customerCompanys = data.customerCompanysName
      formdata.value.customerCompanysT = data.customerCompanys
      checkedCompanys.value = data.customerCompanys.split(',').map((str) => {
        return parseInt(str)
      })
      // 日期
      formdata.value.reportDate = data.createTime.slice(0, 10)
      formdata.value.defaultReportDate = data.createTime.slice(0, 10)
      // 沟通方式
      formdata.value.communicationType = data.communicationTypeName
      formdata.value.communicationTypeT = data.communicationType
      // 客户联系人
      listCheckedId.value = data.customerContactList.map((str) => {
        return parseInt(str.customerContact)
      })
      const result = []
      data.customerContactList.forEach((item, index) => {
        CustomerContactsGetDetail({ id: item.customerContact }).then((r) => {
          if (r.data.isSuccess) {
            result.push(r.data.data.name)
            formdata.value.customerContacts = result.join(',')
          } else {
            showToast(r.data.message)
          }
        })
      })
      formdata.value.customerContactsT = data.customerContactList.map((str) => {
        return { customerContact: str.customerContact, careerExperience: str.careerExperience }
      })
      // 预算分类
      formdata.value.budgetType = data.budgetTypeName
      formdata.value.budgetTypeT = data.budgetType
      // 商机阶段
      formdata.value.businessStage = data.businessStageName
      formdata.value.businessStageT = data.businessStage
      // 预算金额
      formdata.value.budget = data.budget
    } else {
      showToast(res.data.message)
    }
  })
}

// 日历
const showCalendar = ref(false)
const calendarNum = ref('')
const minDate = new Date(new Date().getFullYear() - 2, new Date().getMonth() + 1, new Date().getDate())
const maxDate = new Date(new Date().getFullYear() + 1, new Date().getMonth() + 1, new Date().getDate())
const reportDateShow = () => {
  showCalendar.value = true
  calendarNum.value = 1
}
const supportTimeShow = () => {
  showCalendar.value = true
  calendarNum.value = 2
}
const onConfirm = (date) => {
  if (calendarNum.value === 1) {
    formdata.value.reportDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  } else if (calendarNum.value === 2) {
    formdata.value.supportTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }
  showCalendar.value = false
}
// picker
// 沟通方式
const communicationTypeArr = [
  { text: '线下拜访', value: '1' },
  { text: '线上交流', value: '2' },
  { text: '文案工作', value: '3' },
  { text: '内部沟通', value: '4' },
  { text: '项目管理', value: '5' }
]
const showCommunicationType = () => {
  showPicker.value = true
  typeArr.value = communicationTypeArr
  typeNum.value = 0
}
// 商机阶段
const businessStageTypeArr = [
  { text: '1潜在需求', value: '1' },
  { text: '2意向沟通', value: '2' },
  { text: '3提案准备', value: '3' },
  { text: '4招投标', value: '4' },
  { text: '5合同签署或PO', value: '5' },
  { text: '6成交执行', value: '6' },
  { text: '7结算开票', value: '7' },
  { text: '8收款跟进', value: '8' },
  { text: '9项目中止', value: '9' },
  { text: '10生态圈', value: '10' },
  { text: '11资格准入', value: '11' }
]
const businessStageType = () => {
  showPicker.value = true
  typeArr.value = businessStageTypeArr
  typeNum.value = 1
}
// 预算分类
const budgetTypeArr = [
  { text: '预估金额', value: '1' },
  { text: '合同金额', value: '2' },
  { text: '结算金额', value: '3' }
]
const budgetTypeFun = () => {
  showPicker.value = true
  typeArr.value = budgetTypeArr
  typeNum.value = 2
}
const showPickerType = ({ selectedOptions }) => {
  if (typeNum.value === 0) {
    formdata.value.communicationType = selectedOptions[0]?.text
    formdata.value.communicationTypeT = selectedOptions[0]?.value
    showPicker.value = false
  } else if (typeNum.value === 1) {
    formdata.value.businessStage = selectedOptions[0]?.text
    formdata.value.businessStageT = selectedOptions[0]?.value
    showPicker.value = false
  } else if (typeNum.value === 2) {
    formdata.value.budgetType = selectedOptions[0]?.text
    formdata.value.budgetTypeT = selectedOptions[0]?.value
    showPicker.value = false
  }
}
// 公司简称 支持人
const showCustomerCompanys = () => {
  showPicker1.value = true
  datatype.value = 1
  checkedCompanys.value = formdata.value.customerCompanysT.split(',').map((str) => {
    return parseInt(str)
  })
  checkedSelectIndex.value = checkedSelectIndex.value + 1
}
const showSupporters = () => {
  showPicker1.value = true
  datatype.value = 2
  checkedAllUsers.value = formdata.value.supportersT.split(',').map((str) => {
    return parseInt(str)
  })
  checkedSelectIndex.value = checkedSelectIndex.value + 1
}
// 关闭公司简称、支持人弹窗
const showToasts = () => {
  showPicker1.value = false
  // console.log('csValue', csValue.value)
  if (datatype.value === 1) {
    formdata.value.customerCompanys = csValue.value.checkedCompanysString
    formdata.value.customerCompanysT = csValue.value.checkedCompanysListValue
  } else if (datatype.value === 2) {
    formdata.value.supporters = csValue.value.checkedAllUsersString
    formdata.value.supportersT = csValue.value.checkedAllUsersListValue
  }
}

// 客户联系人
const showCustomerContacts = () => {
  showPicker2.value = true
  console.log(formdata.value.customerContactsT)
  if (formdata.value.customerContactsT !== '') {
    listCheckedId.value = formdata.value.customerContactsT.map((str) => {
      return parseInt(str.customerContact)
    })
  } else {
    listCheckedId.value = ''
  }
  selectCCIndex.value = selectCCIndex.value + 1
}
// 确认客户联系人选项
const showToasts1 = () => {
  // console.log('ccValue', ccValue.value)
  showPicker2.value = false
  if (ccValue.value !== undefined) {
    const result = []
    const result1 = []
    ccValue.value.listCheckedArrString.forEach((item, index) => {
      CustomerContactsGetDetail({ id: item.customerContact }).then((r) => {
        if (r.data.isSuccess) {
          result.push(r.data.data.name)
          result1.push({ customerContact: r.data.data.id, careerExperience: r.data.data.careerExperiences[0].id })
          formdata.value.customerContacts = result.join(',')
          formdata.value.customerContactsT = result1
        } else {
          showToast(r.data.message)
        }
      })
    })
  }
}
// 表单提交
const onSubmit = (values) => {
  if (ccValue.value !== undefined) {
    values.customerContacts = ccValue.value.listCheckedArrString
  } else {
    values.customerContacts = formdata.value.customerContactsT
  }
  values.customerCompanys = formdata.value.customerCompanysT
  values.supporters = formdata.value.supportersT
  values.communicationType = formdata.value.communicationTypeT
  values.businessStage = formdata.value.businessStageT
  values.budgetType = formdata.value.budgetTypeT
  values.theme = route.query.theme
  if (route.query.type === '2') {
    values.id = +route.query.id
  } else if (route.query.type === '3') {
    values.id = 0
  }
  // console.log('submit', values)

  CustomerCommunicationReportsSave(values).then((res) => {
    if (res.data.isSuccess) {
      showToast('提交成功')
      router.push({
        path: '/BCTlistDetails',
        query: { id: route.query.theme }
      })
    } else {
      showToast(res.data.msg)
    }
  })
}
// 清空填写内容
const clearContent = (refName) => {
  if (refName === 'customerContacts') {
    formdata.value.customerContacts = ''
    formdata.value.customerContactsT = ''
  } else if (refName === 'customerCompanys') {
    formdata.value.customerCompanys = ''
    formdata.value.customerCompanysT = ''
  } else if (refName === 'supporters') {
    formdata.value.supporters = ''
    formdata.value.supportersT = ''
  } else if (refName === 'communicationType') {
    formdata.value.communicationType = ''
    formdata.value.communicationTypeT = ''
  } else if (refName === 'businessStage') {
    formdata.value.businessStage = ''
    formdata.value.businessStageT = ''
  } else if (refName === 'budgetType') {
    formdata.value.budgetType = ''
    formdata.value.budgetTypeT = ''
  } else if (refName === 'supportersT') {
    formdata.value.supporters = ''
    formdata.value.supportersT = ''
  }
  formdata.value[refName] = ''
}
</script>

<style lang="scss">
</style>
